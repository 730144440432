import React from 'react'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  handleClose: () => void;
}

const CloseModalButton: React.FC<Props> = ({ handleClose }) => {
  return (
    <div style={{position: 'fixed', right: '0px', top: '0px'}}>
      <IconButton
        color="inherit"
        component="label"
        sx={{borderRadius: '1px'}}
        onClick={()=>{handleClose()}}
      >
        <CloseIcon sx={{fontSize: 50, color: '#fff'}}/>
      </IconButton>
    </div>
  )
}

export default CloseModalButton