import React from 'react'
import Modal from 'react-modal';
import Divider from '@mui/material/Divider';
import useSound from 'use-sound';
import CloseModalButton from './CloseModalButton';

const modalStyle: Modal.Styles = {
  overlay: {
    position: "fixed",
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundColor: 'rgba(0,0,0,.4)',
  },
  content: {
    position: "fixed",
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    margin: 'auto',
    padding: '5px',
    width: '350px',
    maxWidth: '80%',
    height: '210px',
    color: '#000000',
    backgroundColor: '#eff2f5',
    boxShadow: '0 10px 25px 0 rgba(30, 30, 30, .4)',
    borderRadius: '10px',
  }
};

type Props = {
  showSelectFloorModal: boolean;
  setShowSelectFloorModal: React.Dispatch<React.SetStateAction<boolean>>;
  setMoveBeforeEV: React.Dispatch<React.SetStateAction<boolean>>;
  setGracePeriod: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentFloor: React.Dispatch<React.SetStateAction<number>>;
}

const SelectFloorModal: React.FC<Props> = ({ showSelectFloorModal, setShowSelectFloorModal, setMoveBeforeEV, setGracePeriod, setCurrentFloor }) => {
  // エレベータ降りる音
  const [playElevatorButtonSound] = useSound('/sounds/elevatorButton.mp3', { volume: 1 });
  const elevatorButtonClick = (floor: number) => {
    setCurrentFloor(floor);
    setShowSelectFloorModal(false);
    setMoveBeforeEV(true);
    setGracePeriod(true);
    playElevatorButtonSound();
  }
  const handleClose = () => {
    setShowSelectFloorModal(false);
    setMoveBeforeEV(true);
    setGracePeriod(true);
  }
  return (
    <Modal
      isOpen={showSelectFloorModal}
      onRequestClose={()=>{handleClose()}}
      style={modalStyle}
      ariaHideApp={false}
    >
      <Divider/>
      <div
        style={{padding: '10px', fontSize: '20pt', textAlign: 'center', height: '30px', cursor: 'pointer'}}
        onClick={()=>elevatorButtonClick(1)}
      >
        1F
      </div>
      <Divider/>
      <div
        style={{padding: '10px', fontSize: '20pt', textAlign: 'center', height: '30px', cursor: 'pointer'}}
        onClick={()=>elevatorButtonClick(2)}
      >
        2F
      </div>
      <Divider/>
      <div
        style={{padding: '10px', fontSize: '20pt', textAlign: 'center', height: '30px', cursor: 'pointer'}}
        onClick={()=>elevatorButtonClick(3)}
      >
        3F
      </div>
      <Divider/>
      <div
        style={{padding: '10px', fontSize: '20pt', textAlign: 'center', height: '30px', cursor: 'pointer'}}
        onClick={()=>elevatorButtonClick(4)}
      >
        4F
      </div>
      <Divider/>
      <CloseModalButton handleClose={handleClose}/>
    </Modal>
  )
}

export default SelectFloorModal