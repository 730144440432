import React, { useState, useEffect } from 'react';
import './App.css';
import Grid from '@mui/material/Grid';
import Contents from './components/Contents';
import SoundMap from './components/SoundMap';
import ScrollToTopButton from './components/ScrollToTopButton';
import Header from './components/Header';
import SoundBlocks from './components/SoundBlocks';
import Footer from './components/Footer';
import { MAXPAGEWIDTH } from './consts/const';
import BrowserWarning from './components/BrowserWarning';

function App() {
  const [sharedArrayBufferEnable, setSharedArrayBufferEnable] = useState(false);
  const [booting, setBooting] = useState(false);
  const [currentFloor, setCurrentFloor] = useState(1);
  const [gracePeriod, setGracePeriod] = useState(false);
  const [showRoomName, setShowRoomName] = useState('');
  const [moveBeforeRoom, setMoveBeforeRoom] = useState(false);

  useEffect(() => {
    try{
      new SharedArrayBuffer(1);
      setSharedArrayBufferEnable(true);
    }catch{
      setSharedArrayBufferEnable(false);
    }
  }, [])
  return (
    <Grid id='top' container width='100%'justifyContent='center'>
      {!sharedArrayBufferEnable ? <BrowserWarning/> :<></>}
      <Footer/>
      <Grid item style={{width: MAXPAGEWIDTH, maxWidth: '100%'}}>
        <Header/>
        <ScrollToTopButton/>
        <SoundMap
          sharedArrayBufferEnable={sharedArrayBufferEnable}
          setSharedArrayBufferEnable={setSharedArrayBufferEnable}
          booting={booting}
          setBooting={setBooting}
          currentFloor={currentFloor}
          setCurrentFloor={setCurrentFloor}
          gracePeriod={gracePeriod}
          setGracePeriod={setGracePeriod}
          showRoomName={showRoomName}
          setShowRoomName={setShowRoomName}
          moveBeforeRoom={moveBeforeRoom}
          setMoveBeforeRoom={setMoveBeforeRoom}
        />
        <div style={{padding: '15px'}}/>
        <SoundBlocks
          sharedArrayBufferEnable={sharedArrayBufferEnable}
          setSharedArrayBufferEnable={setSharedArrayBufferEnable}
          booting={booting}
          setBooting={setBooting}
        />
        <div style={{padding: '15px'}}/>
        <Contents
          setCurrentFloor={setCurrentFloor}
          setGracePeriod={setGracePeriod}
          setShowRoomName={setShowRoomName}
          setMoveBeforeRoom={setMoveBeforeRoom}
        />
        <div style={{padding: '70px'}}/>
      </Grid>
    </Grid>
  );
}

export default App;
