import React from 'react'
import Modal from 'react-modal';
import Divider from '@mui/material/Divider';
import useSound from 'use-sound';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { MdOutlineWaterDrop } from 'react-icons/md';
import BathroomIcon from '@mui/icons-material/Bathroom';
import CloseModalButton from './CloseModalButton';

const modalStyle: Modal.Styles = {
  overlay: {
    position: "fixed",
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundColor: 'rgba(0,0,0,.4)',
  },
  content: {
    position: "fixed",
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    margin: 'auto',
    padding: '5px',
    width: '250px',
    maxWidth: '80%',
    height: '125px',
    color: '#000000',
    backgroundColor: '#eff2f5',
    boxShadow: '0 10px 25px 0 rgba(30, 30, 30, .4)',
    borderRadius: '10px',
  }
};

type Props = {
  showToiletModal: boolean;
  setShowToiletModal: React.Dispatch<React.SetStateAction<boolean>>;
  showToiletName: string;
  setShowToiletName: React.Dispatch<React.SetStateAction<string>>;
  moveBeforeToilet: boolean;
  setMoveBeforeToilet: React.Dispatch<React.SetStateAction<boolean>>;
  waterSoundOn: boolean;
  setWaterSoundOn: React.Dispatch<React.SetStateAction<boolean>>;
  setGracePeriod: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToiletModal: React.FC<Props> = ({ showToiletModal, setShowToiletModal, showToiletName, setShowToiletName, moveBeforeToilet, setMoveBeforeToilet, waterSoundOn, setWaterSoundOn, setGracePeriod }) => {
  const [playToiletSound] = useSound('/sounds/toilet.mp3', { volume: 0.3 });
  const handleClose = () => {
    setShowToiletModal(false);
    setMoveBeforeToilet(true);
    setGracePeriod(true);
  }
  return (
    <Modal
      isOpen={showToiletModal}
      onRequestClose={()=>{handleClose()}}
      style={modalStyle}
      ariaHideApp={false}
    >
      <Divider/>
      <Grid container justifyContent='center' alignItems='center' style={{height: '60px'}}>
        <Button variant="contained" onClick={()=>setWaterSoundOn(v=>!v)} startIcon={<MdOutlineWaterDrop />} sx={{height: '36.5px', backgroundColor: waterSoundOn ? '#fff' : '#67a8dd', color: waterSoundOn ? '#67a8dd' : '#fff', "&:hover": {backgroundColor: waterSoundOn ? '#fff' : '#67a8dd'}}}>
          {waterSoundOn ? 'WATER SOUND OFF' : 'WATER SOUND ON'}
        </Button>
      </Grid>
      <Divider/>
      <Grid container justifyContent='center' alignItems='center' style={{height: '60px'}}>
        <Button variant="contained" onClick={()=>playToiletSound()} startIcon={<BathroomIcon />} sx={{height: '36.5px', backgroundColor: '#67a8dd', "&:hover": {backgroundColor: '#67a8dd'}}}>
          {`Flush the Toilet`}
        </Button>
      </Grid>
      <Divider/>
      <CloseModalButton handleClose={handleClose}/>
    </Modal>
  )
}

export default ToiletModal