import React from 'react'
import { MAXPAGEWIDTH } from '../consts/const';
import Grid from '@mui/material/Grid';

const Header = () => {
  const getImgWidth = () => document.getElementById('logo')?.clientWidth || MAXPAGEWIDTH;
  const getImgHeight = () => getImgWidth() * (49.31/700);
  return (
    <Grid container justifyContent='center' alignItems='center'>
      <div style={{position: 'relative', width: MAXPAGEWIDTH*0.7, maxWidth: '100%', paddingTop: getImgHeight()*1.7, paddingBottom: getImgHeight()*1.7, paddingLeft: '25px', paddingRight: '25px'}}>
        <div style={{textAlign: 'center'}}>
          <a
            href="https://www.hotel-graphy.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img id='logo' src='/logo/hotelgraphy.svg' style={{width: '100%'}}/>
          </a>
        </div>
        <div style={{padding: getImgHeight()*0.05}}/>
        <img src='/logo/10thAnniversary.png' style={{position: 'absolute', width: '20%', right: '25px'}}/>
      </div>
    </Grid>
  )
}

export default Header