import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import { map } from '../../utils/mathFunc';
import { run } from '../../utils/runScript';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import Stack from '@mui/material/Stack';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {
  ReactP5Wrapper,
  P5CanvasInstance,
  SketchProps,
  Sketch
} from "react-p5-wrapper";
import { MAXPAGEWIDTH } from '../../consts/const';

type MySketchProps = SketchProps & {
  speed: number;
  pause: boolean;
  clear: boolean;
  volume: number;
};

type ScProps = {
  booting: boolean;
  setBooting: React.Dispatch<React.SetStateAction<boolean>>;
  playButtonEnabled: boolean;
  defaultVolume: number;
}

const sketch: Sketch<MySketchProps> = (p: P5CanvasInstance<MySketchProps>) => {
  let width = document.getElementById('box')?.clientWidth || 300;
  let height = document.getElementById('box')?.clientHeight || 300;
  let sizeTras: number = p.min(width, height) / 870;
  let pause: boolean = false;
  let clear: boolean = false;
  let speed: number = 1;
  let volume: number = 0;
  let time: number = 0;
  let particleCount: number = 30;
  let xs: number[] = (new Array<number>(particleCount).fill(0)).map((d) => {return p.random(0, width)});
  let ys: number[] = (new Array<number>(particleCount).fill(0)).map((d) => {return p.random(0, height)});

  p.setup = () => {
    p.createCanvas(width, height);
    p.fill(0);
    p.strokeWeight(p.min(sizeTras, 1)*1.2);
  };
  p.updateWithProps = (props: any) => {
    if (props.speed) {
      speed = props.speed;
    }
    if (props.pause!==undefined) {
      pause = props.pause;
    }
    if (props.clear!==undefined) {
      clear = props.clear;
    }
    if (props.volume!==undefined) {
      volume = props.volume;
    }
  };
  p.draw = () => {
    if (clear) {
      p.clear(255, 255, 255, 255);
      pause = true;
      return;
    }
    p.clear(255, 255, 255, 255); // 前に描画したものをクリア
    // ノイズの描画
    for (let i=0; i<particleCount; i++) {
      let x, y;
      x = width/2 + 1.0*(width/2)*p.map(p.noise(i), 0, 1, -1, 1);
      y = height/2 + 1.5*(height/2)*p.map(p.noise(100+i+0.08*(time+31)*volume/100), 0, 1, -1, 1);
      p.ellipse(x, y, p.min(sizeTras, 1)*2.5);
      if (!pause) {
        ys[i] += 25*volume/100;
      }
      if (ys[i]>height) {ys[i]=p.random(0, height);}
      p.ellipse(xs[i], ys[i], p.min(sizeTras, 1)*3);
    }
    if (!pause) {
      time++;
    }
  }
}

const RiverNoiseSquare: React.FC<ScProps> = ({ booting, setBooting, playButtonEnabled, defaultVolume }) => {
  const masterVolume = 5;
  const minDistance = 10;
  const [volume, setVolume] = useState(defaultVolume);
  const [isPlaying, setIsPlaying] = useState(false);
  const [sNew, setSNew] = useState(false);
  const [freqRange, setFreqRange] = useState<number[]>([475, 2500]);
  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    if (event.type === 'mousedown') {
      return;
    }
    setVolume(newValue as number);
  };
  const handleFreqRangeChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (event.type === 'mousedown') {
      return;
    }
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setFreqRange([Math.min(newValue[0], freqRange[1] - minDistance), freqRange[1]]);
    } else {
      setFreqRange([freqRange[0], Math.max(newValue[1], freqRange[0] + minDistance)]);
    }
  };
  useEffect(() => {
    if(!booting) {return;}
    if (isPlaying) {
      setSNew(true);
      if (sNew) {
        // n_setする
        run(`c_pinknoise(${masterVolume*volume/100}, ${freqRange[0]}, ${freqRange[1]})`);
      } else {
        // s_newする
        run(`s_pinknoise(${masterVolume*volume/100}, ${freqRange[0]}, ${freqRange[1]})`);
      }
    } else {
      if (sNew) {
        // n_setする
        run(`c_pinknoise(0, ${freqRange[0]}, ${freqRange[1]})`);
      }
    }
  }, [isPlaying, volume, freqRange])
  return (
    <Grid id='noise_square' container justifyContent='center' alignItems='center' style={{width: MAXPAGEWIDTH/2, height: MAXPAGEWIDTH/2, maxWidth: '50vw', maxHeight: '50vw'}}>
      <Stack spacing={1} width='100%' direction="row" alignItems="center" justifyContent='flex-end' >
        <Slider
          size='small'
          sx={{width: 'calc(90% - 42px)', color: '#615f60', "& .MuiSlider-thumb": {borderRadius: 0, width: '9px', height: '9px'}}}
          onChange={handleVolumeChange}
          value={volume}
          disabled={!playButtonEnabled}
        />
        <VolumeUp style={{color: playButtonEnabled ? '#615f60' : '#ccc'}}/>
      </Stack>
      <Box id='box' sx={{ width: 'calc(90% - 42px)', height: 'calc(90% - 42px)', border: "solid 1px #CCCCCC", position: 'relative'}}>
        <div style={{position: 'absolute', width: '100%', height: '100%', zIndex: -1}}>
          <ReactP5Wrapper sketch={sketch} speed={0.8} pause={!isPlaying} volume={volume}></ReactP5Wrapper>
        </div>
        <div style={{position: 'absolute', right: 4}}>
          noise
        </div>
        <div style={{cursor: 'pointer', width: 35, height: 35}} onClick={()=>{if(playButtonEnabled)setIsPlaying(v=>!v)}}>
          {isPlaying ? (
            <PauseCircleOutlineIcon color={playButtonEnabled ? 'inherit' : 'disabled'} style={{width: '100%', height: '100%'}}/>
          ) : (
            <PlayCircleOutlineIcon color={playButtonEnabled ? 'inherit' : 'disabled'} style={{width: '100%', height: '100%'}}/>
          )}
        </div>
      </Box>
      <Slider
        size='small'
        color='primary'
        sx={{
          width: 'calc(90% - 42px)', 
          color: '#615f60',
          "& .MuiSlider-thumb": {
            borderRadius: 0, width: '9px', height: '9px'
          },
        }}
        onChange={handleFreqRangeChange}
        value={freqRange}
        min={100}
        max={6000}
        step={10}
        disabled={!playButtonEnabled}
        valueLabelDisplay={(document.getElementById('noise_square')?.clientWidth || 0) >= 300 ? "on" : "auto"}
        valueLabelFormat={(value: number)=>`${value}Hz`}
      />
    </Grid>
  )
}

export default RiverNoiseSquare
