import React from 'react'
import Button from '@mui/material/Button';

const BookButton = () => {
  return (
    <Button variant="contained" sx={{height: '36.5px', backgroundColor: '#325a3d', "&:hover": {backgroundColor: '#819988'}}}>
      <a
        href='https://booking.livelyhotels.com/booking/result?code=fbd5cca81ca624a146ba1da6c4a00ab4&type=plan&uri=https%3A%2F%2Fapi.livelyhotels.com&filter%5B0%5D=2700&order=recommended&is_including_occupied=false&search_type=undated&nights=1&rooms=%5B%7B%22adults%22%3A2%2C%22children%22%3A0%7D%5D&parentUrl=https%3A%2F%2Fwww.hotel-graphy.com&parentPathname=%2F'
        target="_blank" rel="noopener noreferrer"
        style={{color: '#fff', textDecoration: 'none'}}
      >
        BOOK A ROOM
      </a>
    </Button>
  )
}

export default BookButton