import React from 'react'
import Grid from '@mui/material/Grid';
import { Link as Scroll } from 'react-scroll';

const Footer = () => {
  const footerHeight = 25;
  return (
    <Grid container justifyContent='center' alignItems='center' spacing={0} style={{position: 'fixed', bottom: '0px', width: '100vw', height: footerHeight, flexDirection: 'column', textAlign: 'center', backgroundColor: 'rgba(255,255,255,0.5)', color: '#797979', borderRadius: '5px', alignItems: 'center'}}>
      <Grid item xs={12}>
        <Grid container justifyContent='center' alignItems='center'>
          <span style={{fontSize: '8pt'}}>
            made by
          </span>
          <Scroll to="WEB" smooth={true} duration={2000} delay={100} style={{height: footerHeight, cursor: 'pointer'}}>
            <img src='/logo/logo_keitamiyano_rgb_1-5.png' height={footerHeight} style={{opacity: 0.9}}/>
          </Scroll>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Footer