import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import { map } from '../../utils/mathFunc';
import { run } from '../../utils/runScript';
import VolumeUp from '@mui/icons-material/VolumeUp';
import Stack from '@mui/material/Stack';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { FiCircle } from 'react-icons/fi';
import { VscCircleSmall, VscCircle } from 'react-icons/vsc';
import { RiBubbleChartLine } from 'react-icons/ri';
import {
  ReactP5Wrapper,
  P5CanvasInstance,
  SketchProps,
  Sketch
} from "react-p5-wrapper";
import { MAXPAGEWIDTH } from '../../consts/const';

type MySketchProps = SketchProps & {
  speed: number;
  pause: boolean;
  clear: boolean;
  bubbleSize: number[];
  bubbleCount: number;
};

type ScProps = {
  booting: boolean;
  setBooting: React.Dispatch<React.SetStateAction<boolean>>;
  playButtonEnabled: boolean;
  startNodeId: number;
  defaultSize: number[];
  defaultVolume: number;
  defaultBubbleCount: number;
}

const sketch: Sketch<MySketchProps> = (p: P5CanvasInstance<MySketchProps>) => {
  let width = document.getElementById('box')?.clientWidth || 300;
  let height = document.getElementById('box')?.clientHeight || 300;
  let sizeTras: number = p.min(width, height) / 870;
  let pause: boolean = false;
  let clear: boolean = false;
  let speed: number = 1;
  // 泡
  let awaCount: number = 10;
  let bubbleSize: number[] = [40, 90];
  let maxAwaCount: number = 10;
  let time = 0;
  let vertexCount = 50;
  let centerXs: number[] = (new Array<number>(maxAwaCount).fill(0)).map((d) => {return p.random(0, width)});
  let centerYs: number[] = (new Array<number>(maxAwaCount).fill(0)).map((d) => {return p.random(0, height)});
  let maxRadiuses: number[] = (new Array<number>(maxAwaCount).fill(0)).map((d) => {return p.random(p.min(width, height)/bubbleSize[1], p.min(width, height)/bubbleSize[0]);});
  let awaSpeeds: number[] = (new Array<number>(maxAwaCount).fill(0)).map((d) => {return p.random(1.03, 1.1)**speed});
  let rotateSpeeds: number[] = (new Array<number>(maxAwaCount).fill(0)).map((d) => {return p.random(0.0007, 0.003)});
  let shapeSpeeds: number[] = (new Array<number>(maxAwaCount).fill(0)).map((d) => {return p.random(0.01, 0.05)});
  let purupuruSpeeds: number[] = (new Array<number>(maxAwaCount).fill(0)).map((d) => {return p.random(0.1, 0.5)});
  let radiuses: number[] = (new Array<number>(maxAwaCount).fill(0));
  const getTheta = (i: number) => {return 2.*p.PI*i/vertexCount;}
  const getAwaX = (i: number, radius: number, centerX: number, purupuruSpeed: number, rotateSpeed: number, shapeSpeed: number) => {
    let theta = getTheta(i);
    return centerX + radius*0.01*(100.+p.map(p.noise(time*purupuruSpeed), 0, 1, 3, 5)*p.cos(p.noise(time*0.02)*10*p.noise(p.cos(theta+time*0.001),p.sin(theta+time*rotateSpeed),time*shapeSpeed)))*p.cos(theta);
  }
  const getAwaY = (i: number, radius: number, centerY: number, purupuruSpeed: number, rotateSpeed: number, shapeSpeed: number) => {
    let theta = getTheta(i);
    return centerY + radius*0.01*(100.+p.map(p.noise(time*purupuruSpeed), 0, 1, 3, 5)*p.cos(p.noise(time*0.02)*10*p.noise(p.cos(theta+time*0.001),p.sin(theta+time*rotateSpeed),time*shapeSpeed)))*p.sin(theta);
  }
  p.setup = () => {
    p.createCanvas(width, height);
    p.fill(0);
    p.strokeWeight(p.min(sizeTras, 1)*1.2);
  };
  p.updateWithProps = (props: any) => {
    if (props.speed) {
      speed = props.speed;
    }
    if (props.pause!==undefined) {
      pause = props.pause;
    }
    if (props.clear!==undefined) {
      clear = props.clear;
    }
    if (props.bubbleSize!==undefined) {
      bubbleSize = props.bubbleSize;
      if (pause && time===0) {
        radiuses = (new Array<number>(maxAwaCount).fill(0)).map((d) => {return p.random(0, p.min(width, height)/p.max(bubbleSize[0], 2))});
      }
    }
    if (props.bubbleCount!==undefined) {
      awaCount = props.bubbleCount;
    }
  };
  p.draw = () => {
    if (clear) {
      p.clear(255, 255, 255, 255);
      pause = true;
      return;
    }
    p.clear(255, 255, 255, 255); // 前に描画したものをクリア
    // 泡の描画
    for (let i=0; i<awaCount; i++) {
      for (let k=0; k<vertexCount; k++){
        if (k < vertexCount-1) {
          p.line(
            getAwaX(k, radiuses[i], centerXs[i], purupuruSpeeds[i], rotateSpeeds[i], shapeSpeeds[i]),
            getAwaY(k, radiuses[i], centerYs[i], purupuruSpeeds[i], rotateSpeeds[i], shapeSpeeds[i]),
            getAwaX(k+1, radiuses[i], centerXs[i], purupuruSpeeds[i], rotateSpeeds[i], shapeSpeeds[i]),
            getAwaY(k+1, radiuses[i], centerYs[i], purupuruSpeeds[i], rotateSpeeds[i], shapeSpeeds[i])
          );
        } else {
          p.line(
            getAwaX(k, radiuses[i], centerXs[i], purupuruSpeeds[i], rotateSpeeds[i], shapeSpeeds[i]),
            getAwaY(k, radiuses[i], centerYs[i], purupuruSpeeds[i], rotateSpeeds[i], shapeSpeeds[i]),
            getAwaX(0, radiuses[i], centerXs[i], purupuruSpeeds[i], rotateSpeeds[i], shapeSpeeds[i]),
            getAwaY(0, radiuses[i], centerYs[i], purupuruSpeeds[i], rotateSpeeds[i], shapeSpeeds[i])
          );
        }
      }
      if(!pause) {
        if(radiuses[i]<maxRadiuses[i]){
          if(radiuses[i]<1){
            radiuses[i]=1.5;
          }else{
            radiuses[i]*=awaSpeeds[i];
          }
        }else{
          radiuses[i]=0.;
          centerXs[i] = p.random(0, width);
          centerYs[i] = p.random(0, height);
          maxRadiuses[i] = p.random(p.min(width, height)/p.max(bubbleSize[1], 2), p.min(width, height)/p.max(bubbleSize[0], 2));
          awaSpeeds[i] = p.random(1.03, 1.1)**speed;
          rotateSpeeds[i] = p.random(0.0007, 0.003);
          shapeSpeeds[i] = p.random(0.01, 0.03);
          purupuruSpeeds[i] = p.random(0.001, 0.05);
        }
      }
    }
    time++;
  }
}

const BubbleSquare: React.FC<ScProps> = ({ booting, setBooting, playButtonEnabled, startNodeId, defaultSize, defaultVolume, defaultBubbleCount }) => {
  const minDistance = 5;
  const masterVolume = 3;
  const [bubbleSize, setBubbleSize] = useState<number[]>(defaultSize);
  const [bubbleCount, setBubbleCount] = useState<number>(defaultBubbleCount);
  const [bubbleIndex, setBubbleIndex] = useState<number>(0);
  const [bubbleVolume, setBubbleVolume] = useState<number>(defaultVolume);
  const [bubbleNodeId, setBubbleNodeId] = useState<number>(startNodeId);
  const [isPlaying, setIsPlaying] = useState(false);
  const handleBubbleSizeChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (event.type === 'mousedown') {
      return;
    }
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setBubbleSize([Math.min(newValue[0], bubbleSize[1] - minDistance), bubbleSize[1]]);
    } else {
      setBubbleSize([bubbleSize[0], Math.max(newValue[1], bubbleSize[0] + minDistance)]);
    }
  };
  const handleBubbleCountChange = (event: Event, newValue: number | number[]) => {
    if (event.type === 'mousedown') {
      return;
    }
    setBubbleCount(newValue as number);
  };
  const handleBubbleVolumeChange = (event: Event, newValue: number | number[]) => {
    if (event.type === 'mousedown') {
      return;
    }
    setBubbleVolume(newValue as number);
  };
  useEffect(() => {
    if(!booting){return;}
    if(!isPlaying){return;}
    let time = map(Math.random(), 0, 1, 0.5, 20)+1000*((1 - bubbleCount/100)**5);
    let bubbleSizeMin = map(bubbleSize[0], 0, 100, 10, 100);
    let bubbleSizeMax = map(bubbleSize[1], 0, 100, 10, 100);
    setTimeout(()=>{
      setBubbleIndex(1-bubbleIndex);
    }, (time));
    let amp = bubbleCount>0 ? masterVolume * bubbleVolume/100 : 0;
    if(amp<0.01){return;}
    let rand = Math.random();
    let sustain = map(rand, 0, 1, 1/bubbleSizeMax, Math.min(1/bubbleSizeMin, 0.08)) * 1.1;
    let freq = map(Math.sqrt(rand), 0, 1, bubbleSizeMax**2, bubbleSizeMin**2);
    let accelerate = map(rand, 0, 1, Math.sqrt(304/bubbleSizeMax), Math.sqrt(304/bubbleSizeMin));
    let lpf = 5000;
    let pan = map(Math.random(), 0, 1, -0.5, 0.5);
    amp = amp * map(rand*rand, 0, 1, 0.1, 1);
    amp = amp * map(Math.random()*Math.random(), 0, 1, 0, 1);
    run(`s_sinewave(${amp*0.4}, ${sustain}, ${pan}, ${freq}, ${accelerate}, ${lpf}, ${bubbleNodeId})`);
    if(bubbleNodeId<startNodeId+999) {
      setBubbleNodeId(bubbleNodeId+1);
    }else{
      setBubbleNodeId(startNodeId);
    }
  }, [booting, bubbleIndex, isPlaying])

  // useEffect(() => {
  //   console.log(bubbleSize);
  // }, [bubbleSize])

  return (
    <>
      <Grid container justifyContent='center' alignItems='center' style={{width: MAXPAGEWIDTH/2, height: MAXPAGEWIDTH/2, maxWidth: '50vw', maxHeight: '50vw'}}>
        <Stack spacing={1} width='100%' direction="row" alignItems="center" justifyContent='flex-end' >
          {/* <VolumeDown /> */}
          <Slider
            size='small'
            sx={{width: 'calc(90% - 42px)', color: '#615f60', "& .MuiSlider-thumb": {borderRadius: 0, width: '9px', height: '9px'}}}
            onChange={handleBubbleVolumeChange}
            value={bubbleVolume}
            disabled={!playButtonEnabled}
          />
          <VolumeUp style={{color: playButtonEnabled ? '#615f60' : '#ccc'}}/>
        </Stack>
        <Stack spacing={1} height='calc(90% - 42px)' direction="column" alignItems="center" justifyContent='flex-end' >
          <VscCircleSmall/>
          <Slider
            orientation="vertical"
            size='small'
            sx={{height: '90%', color: '#615f60', "& .MuiSlider-thumb": {borderRadius: 0, width: '9px', height: '9px'}}}
            onChange={handleBubbleSizeChange}
            value={bubbleSize}
            disableSwap
            disabled={!playButtonEnabled}
          />
          <FiCircle />
        </Stack>
        <Box id='box' sx={{ width: 'calc(90% - 42px)', height: 'calc(90% - 42px)', border: "solid 1px #CCCCCC", position: 'relative'}}>
          <div style={{position: 'absolute', width: '100%', height: '100%', zIndex: -1}}>
            <ReactP5Wrapper sketch={sketch} speed={0.8} pause={!isPlaying} bubbleSize={bubbleSize} bubbleCount={bubbleCount/15}></ReactP5Wrapper>
          </div>
          <div style={{position: 'absolute', right: 4}}>
            bubble
          </div>
          <div style={{cursor: 'pointer', width: 35, height: 35}} onClick={()=>{if(playButtonEnabled)setIsPlaying(v=>!v)}}>
            {isPlaying ? (
              <PauseCircleOutlineIcon color={playButtonEnabled ? 'inherit' : 'disabled'} style={{width: '100%', height: '100%'}}/>
            ) : (
              <PlayCircleOutlineIcon color={playButtonEnabled ? 'inherit' : 'disabled'} style={{width: '100%', height: '100%'}}/>
            )}
          </div>
        </Box>
        <Stack spacing={1} width='90%' direction="row" alignItems="center" justifyContent='flex-end' >
          <VscCircle />
          <Slider
            size='small'
            color='primary'
            sx={{width: 'calc(90% - 42px)', color: '#615f60', "& .MuiSlider-thumb": {borderRadius: 0, width: '9px', height: '9px'}}}
            onChange={handleBubbleCountChange}
            value={bubbleCount}
            disabled={!playButtonEnabled}
          />
          <RiBubbleChartLine />
        </Stack>
      </Grid>
    </>
  )
}

export default BubbleSquare
