import React from 'react'
import { Link as Scroll } from 'react-scroll';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import IconButton from '@mui/material/IconButton';

const ScrollToTopButton = () => {
  const scrollDuration = 2000;
  return (
    <div style={{position: 'fixed', right: '10px', bottom: '10px', flexDirection: 'column'}}>
      <Scroll to="top" smooth={true} duration={scrollDuration} delay={100}>
        <IconButton color="inherit" component="label" sx={{flexDirection: 'column', borderRadius: '1px'}}>
          <ArrowUpwardIcon fontSize='large'/>
          <span style={{fontSize: '11pt'}}>Top</span>
        </IconButton>
      </Scroll>
    </div>
  )
}

export default ScrollToTopButton