import React, { useState, useEffect, useRef } from 'react'
import Grid from '@mui/material/Grid';
import Draggable from 'react-draggable';
import { run } from '../utils/runScript';
import { isSmartPhone } from '../utils/computerTerminal';
import {
  ReactP5Wrapper,
  P5CanvasInstance,
  SketchProps,
  Sketch
} from "react-p5-wrapper";
import RoomIntroModal from './RoomIntroModal';
import SelectFloorModal from './SelectFloorModal';
import ToiletModal from './ToiletModal';
import { MAXPAGEWIDTH } from '../consts/const';
import useSound from 'use-sound';
import { map } from '../utils/mathFunc';
import { shuffle } from '../utils/arrayFunc';

type MySketchProps = SketchProps & {
  pause: boolean;
  clear: boolean;
  gracePeriod: boolean;
  vx: number;
  vy: number;
  size: number;
  currentFloor: number;
  setCurrentFloor: React.Dispatch<React.SetStateAction<number>>;
  setShowSelectFloorModal: React.Dispatch<React.SetStateAction<boolean>>;
  moveBeforeEV: boolean;
  setShowRoomIntroModal: React.Dispatch<React.SetStateAction<boolean>>;
  showRoomName: string;
  setShowRoomName: React.Dispatch<React.SetStateAction<string>>;
  moveBeforeRoom: boolean;
  setPosition: React.Dispatch<React.SetStateAction<XY>>;
  showToiletModal: boolean;
  setShowToiletModal: React.Dispatch<React.SetStateAction<boolean>>;
  showToiletName: string;
  setShowToiletName: React.Dispatch<React.SetStateAction<string>>;
  moveBeforeToilet: boolean;
  waterSoundOn: boolean;
};

const sketch: Sketch<MySketchProps> = (p: P5CanvasInstance<MySketchProps>) => {
  let width = document.getElementById('map')?.clientWidth || MAXPAGEWIDTH;
  let height = width * (1500/1960);
  let pause = false;
  let clear = false;
  let gracePeriod = false;
  let x = 9.7;
  let y = 27;
  let vx = 0;
  let vy = 0;
  let size = p.min(width/40, 20);
  let time = 0;
  let currentFloor = 1;
  let setCurrentFloor: React.Dispatch<React.SetStateAction<number>>;
  let setShowSelectFloorModal: React.Dispatch<React.SetStateAction<boolean>>;
  let moveBeforeEV = false;
  let setShowRoomIntroModal: React.Dispatch<React.SetStateAction<boolean>>;
  let showRoomName = '';
  let setShowRoomName: React.Dispatch<React.SetStateAction<string>>;
  let moveBeforeRoom: boolean;
  let setPosition: React.Dispatch<React.SetStateAction<XY>>;
  let showToiletModal: boolean;
  let setShowToiletModal: React.Dispatch<React.SetStateAction<boolean>>;
  let showToiletName: string;
  let setShowToiletName: React.Dispatch<React.SetStateAction<string>>;
  let moveBeforeToilet: boolean;

  // 川の流れのようなノイズ
  let waterSoundOn = false;
  let waterFall = 0;
  let sizeTras: number = height / 1000;

  p.setup = () => {
    p.createCanvas(width, height);
    p.fill(0);
  };
  p.updateWithProps = (props: any) => {
    if (props.pause!==undefined) {
      pause = props.pause;
    }
    if (props.clear!==undefined) {
      clear = props.clear;
    }
    if (props.gracePeriod!==undefined) {
      gracePeriod = props.gracePeriod;
    }
    if (props.vx!==undefined) {
      vx = props.vx;
    }
    if (props.vy!==undefined) {
      vy = props.vy;
    }
    if (props.size!==undefined) {
      size = props.size;
    }
    if (props.currentFloor!==undefined) {
      currentFloor = props.currentFloor;
    }
    if (props.setCurrentFloor!==undefined) {
      setCurrentFloor = props.setCurrentFloor;
    }
    if (props.setShowSelectFloorModal!==undefined) {
      setShowSelectFloorModal = props.setShowSelectFloorModal;
    }
    if (props.moveBeforeEV!==undefined) {
      moveBeforeEV = props.moveBeforeEV;
    }
    if (props.setShowRoomIntroModal!==undefined) {
      setShowRoomIntroModal = props.setShowRoomIntroModal;
    }
    if (props.setShowRoomName!==undefined) {
      setShowRoomName = props.setShowRoomName;
    }
    if (props.showRoomName!==undefined) {
      showRoomName = props.showRoomName;
    }
    if (props.moveBeforeRoom!==undefined) {
      moveBeforeRoom = props.moveBeforeRoom;
    }
    if (props.setPosition!==undefined) {
      setPosition = props.setPosition;
    }
    if (props.showToiletModal!==undefined) {
      showToiletModal = props.showToiletModal;
    }
    if (props.setShowToiletModal!==undefined) {
      setShowToiletModal = props.setShowToiletModal;
    }
    if (props.showToiletName!==undefined) {
      showToiletName = props.showToiletName;
    }
    if (props.setShowToiletName!==undefined) {
      setShowToiletName = props.setShowToiletName;
    }
    if (props.moveBeforeToilet!==undefined) {
      moveBeforeToilet = props.moveBeforeToilet;
    }
    if (props.waterSoundOn!==undefined) {
      waterSoundOn = props.waterSoundOn;
    }
  };
  p.draw = () => {
    if (clear) {
      p.clear(255, 255, 255, 255);
      pause = true;
      return;
    }
    p.clear(255, 255, 255, 255); // 前に描画したものをクリア

    // 川の流れのようなノイズ
    p.fill(255, 0);
    p.stroke(200);
    p.strokeWeight(p.min(sizeTras, 1)*1);

    if(waterSoundOn) {
      if(waterFall<1){
        waterFall+=0.01;
      }else{
        waterFall=1;
      }
    }else{
      if(waterFall>0){
        waterFall-=0.01;
      }else{
        waterFall=0;
      }
    }

    let noiseScale = 0.02;
    for (var i=0; i<width; i++) {
      let noiseVal = p.noise(i*noiseScale, time*0.02);
      if (i<width*0.03) {
        noiseVal = p.map(i, 0, width*0.03, 1, noiseVal);
      }
      if (i>width*0.97) {
        noiseVal = p.map(i, width*0.97, width, noiseVal, 1);
      }
      p.strokeWeight(1);
      p.stroke(p.map(noiseVal, 0, 1, 0.1, 1)*255, p.map(noiseVal, 0, 1, 50, 0)*waterFall);
      p.line(i, 0, i, height);
    }

    // 位置マーカー(赤) 
    p.fill(255, 255, 255, 150);
    p.stroke(255, 0, 0);
    p.strokeWeight(1.5);
    setPosition({x: x, y: y});
    p.ellipse(x*width/100, y*height/100, size*p.map(p.sin(time*0.1), -1, 1, 0.7, 1));
    
    let v_ = p.sqrt(vx**2 + vy**2);
    let vth = 100;
    if (v_>=vth) {
      vx = vx * (vth / v_);
      vy = vy * (vth / v_);
    }

    if (!gracePeriod) {
      x += vx*0.005;
      y += vy*0.005;
    }

    if(x<=1.5){x=1.5;}
    if(x>=98.5){x=98.5;}
    if(y<=1.5){y=1.5;}
    if(y>=98.5){y=98.5;}

    // console.log(x, y);
    if(gracePeriod && moveBeforeEV) {
      // エレベータ降りたらエレベータの前に移動
      if (currentFloor===1) {
        x = 27.75;
        y = 33.5;
      }
      if (currentFloor===2) {
        x = 27.0;
        y = 34.0;
      }
      if (currentFloor===3) {
        x = 26.75;
        y = 31.0;
      }
      if (currentFloor===4) {
        x = 25.0;
        y = 31.5;
      }
    }
    if(gracePeriod && moveBeforeRoom) {
      if (showRoomName==='STUDIO') {
        x = 18;
        y = 22;
      }
      if (showRoomName==='LOUNGE') {
        x = 48.1;
        y = 24.7;
      }
      if (showRoomName==='DJ') {
        x = 54.33;
        y = 59;
      }
      if (showRoomName==='CAFE') {
        x = 18.86;
        y = 32.6;
      }
      if (showRoomName==='108') {
        x = 70.5;
        y = 73;
      }
      if (showRoomName==='206') {
        x = 75;
        y = 74.5;
      }
      if (showRoomName==='212') {
        x = 47;
        y = 29.5;
      }
      if (showRoomName==='301') {
        x = 72;
        y = 73.5;
      }
      if (showRoomName==='312') {
        x = 46.5;
        y = 27;
      }
      if (showRoomName==='ROOFTOP' || showRoomName=='ROOFTOP ') {
        x = 40.2
        y = 36.5;
      }
      if (showRoomName==='CORRIDOR') {
        x = 40.71;
        y = 32.62;
      }
      if (showRoomName==='' || showRoomName=='ENTRANCE') {
        x = 9.7;
        y = 27;
      }
    }
    if (gracePeriod && moveBeforeToilet) {
      if (showToiletName==='Toilet1_1') {
        x = 24.29;
        y = 49.84;
      }
      if (showToiletName==='Toilet1_2') {
        x = 46.85;
        y = 64.46;
      }
      if (showToiletName==='Toilet2_1') {
        x = 46.81;
        y = 49.01;
      }
      if (showToiletName==='Toilet2_2') {
        x = 64.81;
        y = 75.51;
      }
      if (showToiletName==='Toilet3_1') {
        x = 46.48;
        y = 46.49;
      }
      if (showToiletName==='Toilet3_2') {
        x = 64.42;
        y = 72.74;
      }
      if (showToiletName==='Toilet4_1') {
        x = 62.52;
        y = 73.20;
      }
    }
    if (!gracePeriod) {
      if (currentFloor===1) {
        // 一階
        // to2F x: 36.25~43.5 y: 36.25~38.75
        // EV x: 26.25~29.25 y: 40.25~42.5
        // 自転車 x: 3.25~7 y: 6.25~16.5
        // STUDIO x: 10.25~20.25 y: 4.25~18.25
        // LOUNGE x: 43.97~58.97 y: 4.37~18.62
        // 108 x: 67.75~72.75 y: 52.75~68.75
        if (x>36.25 && x<43.5 && y>36.25 && y<38.75) {
          // 階段登る
          setCurrentFloor(2);
          x = 33;
          y = 37.5;
        }
        if (x>26.25 && x<29.25 && y>40.25 && y<42.5) {
          // エレベータ乗る
          setShowSelectFloorModal(true);
        }
        if (x>10.25 && x<20.25 && y>4.25 && y<18.25) {
          // STUDIO
          setShowRoomIntroModal(true);
          setShowRoomName('STUDIO');
        }
        if (x>43.97 && x<58.97 && y>4.37 && y<18.62) {
          // LOUNGE
          setShowRoomIntroModal(true);
          setShowRoomName('LOUNGE');
        }
        if (x>52.29 && x<57.29 && y>41 && y<53.37) {
          // DJ
          setShowRoomIntroModal(true);
          setShowRoomName('DJ');
        }
        if (x>9.86 && x<21.11 && y>42 && y<57.36) {
          // CAFE
          setShowRoomIntroModal(true);
          setShowRoomName('CAFE');
        }
        if (x>1.5 && x<5.6 && y>21.11 && y<28.98) {
          // ENTRANCE
          setShowRoomIntroModal(true);
          setShowRoomName('ENTRANCE');
        }
        if (x>38.89 && x<42.89 && y>24.86 && y<28.12) {
          // CORRIDOR
          setShowRoomIntroModal(true);
          setShowRoomName('CORRIDOR');
        }
        if (x>67.75 && x<72.75 && y>52.75 && y<68.75) {
          // 108
          setShowRoomIntroModal(true);
          setShowRoomName('108');
        }
        if ((x>23.82 && x<28.07 && y>54.30 && y<57.55)||(x>30.07 && x<32.32 && y>49.30 && y<52.80)) {
          // Toilet1_1
          setShowToiletModal(true);
          setShowToiletName('Toilet1_1')
        }
        if ((x>40.60 && x<43.60 && y>60.46 && y<63.96)) {
          // Toilet1_2
          setShowToiletModal(true);
          setShowToiletName('Toilet1_2')
        }
      } else if (currentFloor===2) {
        // to3F x: 36.25~43.0 y: 36.5~38.75
        // to1F x: 50.5~59.8 y: 80.5~82.7
        // EV x: 25.5~28.75 y: 41~43.5
        // 206 x: 69.25~81.75 y: 58~70.25
        // 212 x: 45.75~59.5 y: 10.5~21 or x: 45.75~47.75 y: 21~25.25
        if (x>36.25 && x<43.0 && y>36.5 && y<38.75) {
          // 階段登る
          setCurrentFloor(3);
          x = 32.5;
          y = 35.5;
        }
        if (x>50.5 && x<59.8 && y>80.5 && y<82.7) {
          // 階段降りる
          setCurrentFloor(1);
          x = 46.6;
          y = 79.1;
        }
        if (x>25.5 && x<28.75 && y>41 && y<43.5) {
          // エレベータ乗る
          setShowSelectFloorModal(true);
        }
        if (x>69.25 && x<81.75 && y>58.0 && y<70.25) {
          // 206
          setShowRoomIntroModal(true);
          setShowRoomName('206');
        }
        if ((x>45.75 && x<59.5 && y>10.5 && y<21)||(x>45.75 && x<47.75 && y>21 && y<25.25)) {
          // 212
          setShowRoomIntroModal(true);
          setShowRoomName('212');
        }
        if ((x>41.55 && x<43.55 && y>46.65 && y<51.65)) {
          // Toilet2_1
          setShowToiletModal(true);
          setShowToiletName('Toilet2_1')
        }
        if ((x>62.06 && x<64.31 && y>69.51 && y<71.5)||(x>62.31 && x<66.81 && y>79.76 && y<83.76)) {
          // Toilet2_2
          setShowToiletModal(true);
          setShowToiletName('Toilet2_2')
        }
      } else if (currentFloor===3) {
        // to4F x: 36.1~43.0 y: 34.0~36.0
        // to2F x: 50.1~59.8 y: 77.5~80.1
        // EV x: 25.25~28.5 y: 38.25~40.75
        // 301 x: 68.98~73.98 y: 77.98~92.48
        // 312 x: 45.5~59.5 y: 8~18.25 or x: 45.5~47.5 y: 18.25~22.5
        if (x>36.1 && x<43.0 && y>34.0 && y<36.0) {
          // 階段登る
          setCurrentFloor(4);
          x = 30.5;
          y = 34.75;
        }
        if (x>50.1 && x<59.8 && y>77.5 && y<80.1) {
          // 階段降りる
          setCurrentFloor(2);
          x = 45.9;
          y = 81.0;
        }
        if (x>25.25 && x<28.5 && y>38.25 && y<40.75) {
          // エレベータ乗る
          setShowSelectFloorModal(true);
        }
        if (x>68.98 && x<73.98 && y>77.98 && y<92.48) {
          // 301
          setShowRoomIntroModal(true);
          setShowRoomName('301');
        }
        if ((x>45.5 && x<59.5 && y>8 && y<18.25)||(x>45.5 && x<47.5 && y>18.25 && y<22.5)) {
          // 312
          setShowRoomIntroModal(true);
          setShowRoomName('312');
        }
        if ((x>41.52 && x<43.59 && y>44.18 && y<48.96)) {
          // Toilet3_1
          setShowToiletModal(true);
          setShowToiletName('Toilet3_1')
        }
        if ((x>61.93 && x<64.18 && y>66.56 && y<68.67)||(x>61.95 && x<66.34 && y>77.01 && y<81.01)) {
          // Toilet3_2
          setShowToiletModal(true);
          setShowToiletName('Toilet3_2');
        }
      }  else if (currentFloor===4) {
        // toRT x: 34.3~40.8 y: 34.9~36.18
        // to3F x: 48.75~57.75 y: 78.5~80.5
        // EV x: 23.5~26.5 y: 38.5~41.0
        if (x>31.07 && x<36.08 && y>27.48 && y<32.5) {
          // RoofTop1
          setShowRoomIntroModal(true);
          setShowRoomName('ROOFTOP');
        }
        if (x>38.58 && x<44.42 && y>27.48 && y<32.5) {
          // RoofTop2
          setShowRoomIntroModal(true);
          setShowRoomName('ROOFTOP ');
        }
        if (x>48.75 && x<57.75 && y>78.5 && y<80.5) {
          setCurrentFloor(3);
          x = 45.53;
          y = 78.5;
        }
        if (x>23.5 && x<26.5 && y>38.5 && y<41.0) {
          // エレベータ乗る
          setShowSelectFloorModal(true);
        }
        if ((x>59.92 && x<62.42 && y>66.93 && y<68.93)||(x>60.54 && x<66.78 && y>77.54 && y<81.08)) {
          // Toilet4_1
          setShowToiletModal(true);
          setShowToiletName('Toilet4_1');
        }
      }
    }

    if(!pause)time++;
  }
}

type XY = {
  x: number;
  y: number;
}

type ScProps = {
  sharedArrayBufferEnable: boolean;
  setSharedArrayBufferEnable: React.Dispatch<React.SetStateAction<boolean>>;
  booting: boolean;
  setBooting: React.Dispatch<React.SetStateAction<boolean>>;
  currentFloor: number;
  setCurrentFloor: React.Dispatch<React.SetStateAction<number>>;
  gracePeriod: boolean;
  setGracePeriod: React.Dispatch<React.SetStateAction<boolean>>;
  showRoomName: string;
  setShowRoomName: React.Dispatch<React.SetStateAction<string>>;
  moveBeforeRoom: boolean;
  setMoveBeforeRoom: React.Dispatch<React.SetStateAction<boolean>>;
}

const SoundMap: React.FC<ScProps> = ({ sharedArrayBufferEnable, setSharedArrayBufferEnable, booting, setBooting, currentFloor, setCurrentFloor, gracePeriod, setGracePeriod, showRoomName, setShowRoomName, moveBeforeRoom, setMoveBeforeRoom }) => {
  const nodeRef = useRef(null);
  const [isDrag, setIsDrag] = useState(false);
  const [controllVX, setControllVY] = useState<XY>({x: 0, y: 0})
  const [showSelectFloorModal, setShowSelectFloorModal] = useState(false);
  const [showRoomIntroModal, setShowRoomIntroModal] = useState(false);
  const [moveBeforeEV, setMoveBeforeEV] = useState(false);
  const [showToiletModal, setShowToiletModal] = useState(false);
  const [showToiletName, setShowToiletName] = useState('');
  const [moveBeforeToilet, setMoveBeforeToilet] = useState(false);
  const [position, setPosition] = useState<XY>({x: 0, y: 0});
  const getImgWidth = () => document.getElementById('hotelmap')?.clientWidth || MAXPAGEWIDTH;
  const getImgHeight = () => getImgWidth() * (1500/1960);
  const getControllButtonDefaultX = () => getImgWidth()- getImgHeight() * (0.27+0.18/2);
  const getControllButtonDefaultY = () => getImgHeight() * (0.27-0.18/2);


  const masterVolume = 1.0;
  const [playingSoundObjectsCount, setPlayingSoundObjectsCount] = useState(0);
  // 屋外のノイズ
  const [isPlayingNoise, setIsPlayingNoise] = useState(false);
  const [sNewNoise, setSNewNoise] = useState(false);
  const noiseNodeID = 1006;
  // 自転車のベル
  const [playBicycleSound] = useSound('/sounds/bicycle.mp3', { volume: 0.1*masterVolume });
  const [bicycleIsReady, setBicycleIsReady] = useState(true);
  // エレベータ開く音
  const [playElevatorSound] = useSound('/sounds/elevator.mp3', { volume: 0.2*masterVolume });
  const [elevatorIsReady, setElevatorIsReady] = useState(true);
  // 階段歩く音
  const [playStairsSound] = useSound('/sounds/stairs.mp3', { volume: 0.7*masterVolume });

  // ここからAssemble Sound Objects

  // ギターのハーモニクスF#
  const [playHarmonicsFSSound] = useSound('/sounds/harmonicsFS.mp3', { volume: 0.35*masterVolume });
  const [harmonicsFSInd, setHarmonicsFSInd] = useState(0);
  // ギターのハーモニクスB
  const [playHarmonicsBSound] = useSound('/sounds/harmonicsB.mp3', { volume: 0.25*masterVolume });
  const [harmonicsBInd, setHarmonicsBInd] = useState(0);
  // 雰囲気のあるシンセの音
  const zureVolume = 2.0;
  const [zureInd, setZureInd] = useState(0);
  const zureStartNodeId = 2001;
  const [zureNodeId, setZureNodeId] = useState(zureStartNodeId);
  // 水の音
  const [waterSoundOn, setWaterSoundOn] = useState(false);
  const [bubbleIndex, setBubbleIndex] = useState(0);
  const bubbleStartNodeId = 4001;
  const [bubbleNodeId, setBubbleNodeId] = useState(bubbleStartNodeId);
  const [isPlayingRiverNoise, setIsPlayingRiverNoise] = useState(false);
  const [sNewRiverNoise, setSNewRIverNoise] = useState(false);
  const riverNoiseNodeID = 1000;

  const isTouchDevice = isSmartPhone();

  const boot = () => {
    run(`boot();d_rain();d_zure2();d_sinewave();d_pinknoise();`);
    setBooting(true);
  }

  const quit = () => {
    run(`quit();`);
    setBooting(false);
  }

  const handleDrag = (event: any, data: any) => {
    let vx = data.x-getControllButtonDefaultX();
    let vy = data.y-getControllButtonDefaultY();
    if (showSelectFloorModal || showRoomIntroModal || showToiletModal) {
      setControllVY({x: 0, y: 0});
    } else {
      setControllVY({x: vx, y: vy});
    }
  }

  useEffect(() => {
    const preventDefault = (e: any) => e.preventDefault();
    if (isDrag) {
      document.addEventListener('touchmove', preventDefault, {passive: false});
    } else {
      document.removeEventListener('touchmove', preventDefault, false);
      setControllVY({x: 0, y: 0})
    }
    return () => document.removeEventListener('touchmove', preventDefault, false);
  }, [isDrag])

  useEffect(() => {
    setGracePeriod(true);
    // 階段歩く音鳴らす
    if (!moveBeforeEV) {
      playStairsSound();
    }
  }, [currentFloor])

  useEffect(() => {
    if(gracePeriod) {
      setTimeout(()=>setGracePeriod(false), MAXPAGEWIDTH);
    }
    if(!showSelectFloorModal) {
      setTimeout(()=>setGracePeriod(false), MAXPAGEWIDTH/2);
    }
    if(!showRoomIntroModal) {
      setTimeout(()=>setGracePeriod(false), MAXPAGEWIDTH/2);
    }
  }, [gracePeriod, showSelectFloorModal, showRoomIntroModal])

  useEffect(() => {
    setControllVY({x: 0, y: 0});
  }, [showSelectFloorModal, showRoomIntroModal, showToiletModal])

  useEffect(() => {
    if(!booting) {return;}
    if(controllVX.x!==0 || controllVX.y!==0){
      setIsPlayingNoise(true);
      setIsPlayingRiverNoise(true);
    }

    if (isPlayingNoise) {
      setSNewNoise(true);
      if (!sNewNoise) {
        // s_newする
        run(`s_pinknoise(0, ${100}, ${1500}, ${noiseNodeID})`);
      }
    }

    if (isPlayingRiverNoise) {
      setSNewRIverNoise(true);
      if (!sNewRiverNoise) {
        run(`s_pinknoise(0, ${475}, ${2500}, ${riverNoiseNodeID})`);
      }
    }
  }, [booting, controllVX])

  useEffect(() => {
    if(!booting) {return;}

    // ノイズ
    let noiseVolume = 0.6;

    if (currentFloor===1 && (position.x<=7.75 || (position.x>7.75 && position.x<=38.5 && position.y>=59.6) || (position.x>38.5 && position.x<=65.3 && position.y>=83.0))) {
      noiseVolume *= 1;
    } else {
      noiseVolume *= 0;
    }

    if (isPlayingNoise) {
      if (sNewNoise) {
        // n_setする
        run(`c_pinknoise(${masterVolume*noiseVolume}, ${100}, ${1500}, ${noiseNodeID})`);
      }
    } else {
      if (sNewNoise) {
        // n_setする
        run(`c_pinknoise(0, ${100}, ${1500}, ${noiseNodeID})`);
      }
    }

    // 自転車のベル

    if (currentFloor===1 && ((position.x>3.38 && position.x<=7.13 && position.y>=7.08 && position.y<=16.085))) {
      if (bicycleIsReady) {
        playBicycleSound();
        setBicycleIsReady(false);
      }
    } else {
      setBicycleIsReady(true);
    }

    // エレベータ開く音

    if (
      (currentFloor===1 && ((position.x>26.25 && position.x<=29.25 && position.y>=40.25 && position.y<=42.5)))
      || (currentFloor===2 && ((position.x>25.5 && position.x<=28.75 && position.y>=41 && position.y<=43.5)))
      || (currentFloor===3 && ((position.x>25.25 && position.x<=28.5 && position.y>=38.25 && position.y<=40.75)))
      || (currentFloor===4 && ((position.x>23.5 && position.x<=26.5 && position.y>=38.5 && position.y<=41.0)))
    ) {
      if (elevatorIsReady) {
        playElevatorSound();
        setElevatorIsReady(false);
      }
    } else {
      setElevatorIsReady(true);
    }


  }, [position, booting, isPlayingNoise, bicycleIsReady, elevatorIsReady])

  useEffect(() => {
    if(!booting){return;}
    if(!showRoomIntroModal){return;}
    if((!/^\d*$/.test(showRoomName)) && showRoomName!=='STUDIO' && showRoomName!=='LOUNGE'){return;}
    let time = 4100 + map(Math.random(), 0, 1, 0, 1000);
    setTimeout(()=>{
      setHarmonicsFSInd(1-harmonicsFSInd);
      if(Math.random()<0.1)playHarmonicsFSSound();
    }, (time));
  }, [booting, harmonicsFSInd, showRoomIntroModal])

  useEffect(() => {
    if(!booting){return;}
    if(!showRoomIntroModal){return;}
    if((!/^\d*$/.test(showRoomName)) && showRoomName!=='STUDIO' && showRoomName!=='LOUNGE'){return;}
    let time = 4000 + map(Math.random(), 0, 1, 0, 1000);
    setTimeout(()=>{
      setHarmonicsBInd(1-harmonicsBInd);
      if(Math.random()<0.1)playHarmonicsBSound();
    }, (time));
  }, [booting, harmonicsBInd, showRoomIntroModal])

  useEffect(() => {
    if(!booting){return;}
    if(!showRoomIntroModal){return;}
    if((!/^\d*$/.test(showRoomName)) && showRoomName!=='STUDIO' && showRoomName!=='LOUNGE'){return;}
    let time = 1000 + map(Math.random(), 0, 1, 0, 3000);
    setTimeout(()=>{
      setZureInd(1-zureInd);
    }, (time));
    let freqChange = 1;
    if (currentFloor===2) {freqChange=0.5**(3/12)}
    if (currentFloor===3) {freqChange=2**(2/12)}
    let zureFreq1_ = shuffle([493.883/2, 493.883, 440/4, 440/4, 440/2, 440/4])[0]*freqChange;
    let zureFreq2_ = shuffle([277.183/1, 277.183/2, 277.183*2, 415.305*2])[0]*freqChange;
    let zureFreq3_ = shuffle([311.127, 329.628/2, 329.628/1, 440, 311.127/2])[0]*freqChange;
    let zureFreq4_ = shuffle([415.305/1, 415.305/2, 493.883, 440/2, 493.883/4])[0]*freqChange;
    let zureVolume1_ = shuffle([0, 0.2, 0.8, 1])[0];
    let zureVolume2_ = shuffle([0, 0.2, 0.8, 1])[1];
    let zureVolume3_ = shuffle([0, 0.2, 0.8, 1])[2];
    let zureVolume4_ = shuffle([0, 0.2, 0.8, 1])[3];
    setTimeout(()=>{
      run(`s_zure2(${zureVolume1_*zureVolume}, ${zureFreq1_}, ${Math.random()}, ${10}, ${zureNodeId})`);
      run(`s_zure2(${zureVolume2_*zureVolume}, ${zureFreq2_}, ${Math.random()*0.8}, ${10}, ${zureNodeId+1})`);
      run(`s_zure2(${zureVolume3_*zureVolume}, ${zureFreq3_}, ${Math.random()*0.4}, ${10}, ${zureNodeId+2})`);
      run(`s_zure2(${zureVolume4_*zureVolume}, ${zureFreq4_}, ${Math.random()*0.1}, ${10}, ${zureNodeId+3})`);
    }, (100));

    if(zureNodeId<zureStartNodeId+999) {
      setZureNodeId(zureNodeId+4);
    }else{
      setZureNodeId(zureStartNodeId);
    }
  }, [showRoomIntroModal, zureInd])

  useEffect(() => {
    if(!booting){return;}
    if(!waterSoundOn){return;}
    let bubbleVolume = 20;
    let bubbleCount = 70;
    let bubbleSize = [15, 80];
    let time = map(Math.random(), 0, 1, 0.5, 20)+1000*((1 - bubbleCount/100)**5);
    let bubbleSizeMin = map(bubbleSize[0], 0, 100, 10, 100);
    let bubbleSizeMax = map(bubbleSize[1], 0, 100, 10, 100);
    setTimeout(()=>{
      setBubbleIndex(1-bubbleIndex);
    }, (time));
    let amp = bubbleCount>0 ? masterVolume * bubbleVolume/100 : 0;
    if(amp<0.01){return;}
    let rand = Math.random();
    let sustain = map(rand, 0, 1, 1/bubbleSizeMax, Math.min(1/bubbleSizeMin, 0.08)) * 1.1;
    let freq = map(Math.sqrt(rand), 0, 1, bubbleSizeMax**2, bubbleSizeMin**2);
    let accelerate = map(rand, 0, 1, Math.sqrt(304/bubbleSizeMax), Math.sqrt(304/bubbleSizeMin));
    let lpf = 5000;
    let pan = map(Math.random(), 0, 1, -0.2, 0.2);
    amp = amp * map(rand*rand, 0, 1, 0.1, 1);
    amp = amp * map(Math.random()*Math.random(), 0, 1, 0, 1);
    run(`s_sinewave(${amp*0.4}, ${sustain}, ${pan}, ${freq}, ${accelerate}, ${lpf}, ${bubbleNodeId})`);
    if(bubbleNodeId<bubbleStartNodeId+999) {
      setBubbleNodeId(bubbleNodeId+1);
    }else{
      setBubbleNodeId(bubbleStartNodeId);
    }
  }, [booting, bubbleIndex, waterSoundOn])

  useEffect(() => {
    const riverNoiseVolume = 0.4;
    if (waterSoundOn) {
      if (sNewRiverNoise) {
        // n_setする
        run(`c_pinknoise(${masterVolume*riverNoiseVolume}, ${475}, ${2500}, ${riverNoiseNodeID})`);
      }
    } else {
      if (sNewNoise) {
        // n_setする
        run(`c_pinknoise(0, ${475}, ${2500}, ${riverNoiseNodeID})`);
      }
    }
  }, [waterSoundOn])

  useEffect(() => {
    setGracePeriod(false);
    setMoveBeforeEV(false);
    setMoveBeforeRoom(false);
    setMoveBeforeToilet(false);
  }, [])

  return (
    <div id='map' style={{overflowX: 'hidden'}}>
      {/* <div style={{position: 'fixed', left: 0}}>{`x: ${position.x}, y: ${position.y}`}</div> */}
      <div style={{paddingLeft: '21px', paddingRight: '21px',paddingTop: '0px', paddingBottom: '15px', fontSize: '13pt', color: '#1c1e33'}}>
        {`音の地図　sound map`}
      </div>
      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33'}}>
        {`ホテルグラフィー根津の10周年コンテンツや常設のエリアを、電子音と共にご案内します。`}
      </div>
      <div style={{padding: '3px'}}/>
      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33'}}>
        {`The sound guide will take you through the 10th anniversary contents and permanent areas of HOTEL GRAPHY NEZU.`}
      </div>

      <div style={{position: 'relative', height: getImgHeight()}}>
        <div style={{position: 'absolute'}}>
          <img id='hotelmap' src={`/graphy_maps/${currentFloor}F.png`} style={{width: MAXPAGEWIDTH, maxWidth: '100%', position: 'relative', zIndex: -1}}/>
        </div>
        <div style={{position: 'absolute'}}>
          <ReactP5Wrapper
            sketch={sketch}
            clear={false}
            pause={!booting}
            gracePeriod={gracePeriod}
            vx={controllVX.x}
            vy={controllVX.y}
            currentFloor={currentFloor}
            setCurrentFloor={setCurrentFloor}
            setShowSelectFloorModal={setShowSelectFloorModal}
            moveBeforeEV={moveBeforeEV}
            setShowRoomIntroModal={setShowRoomIntroModal}
            showRoomName={showRoomName}
            setShowRoomName={setShowRoomName}
            moveBeforeRoom={moveBeforeRoom}
            setPosition={setPosition}
            showToiletModal={showToiletModal}
            setShowToiletModal={setShowToiletModal}
            showToiletName={showToiletName}
            setShowToiletName={setShowToiletName}
            moveBeforeToilet={moveBeforeToilet}
            waterSoundOn={waterSoundOn}
          >
          </ReactP5Wrapper>
        </div>
        {currentFloor===1 ? (
          <>
            {/* STUDIO x: 10.25~20.25 y: 4.25~18.25 */}
            <img src={`/studio/0.jpg`} style={{position: 'absolute', width: getImgWidth()*0.05, left: getImgWidth()*0.095, top: getImgHeight()*0.085, zIndex: -1}}/>
            <img src={`/studio/1.jpg`} style={{position: 'absolute', width: getImgWidth()*0.05, left: getImgWidth()*0.155, top: getImgHeight()*0.085, zIndex: -1}}/>
            {/* 108 x: 67.75~72.75 y: 52.75~68.75 */}
            <img src={`/art_rooms/108_HagoromoOkamoto.jpg`} style={{position: 'absolute', width: getImgWidth()*0.069, left: getImgWidth()*0.668, top: getImgHeight()*0.54, zIndex: -1}}/>
            {/* Lounge x: 43.97~58.97 y: 4.37~18.62 */}
            <img src={`/lounge/0.jpg`} style={{position: 'absolute', width: getImgWidth()*0.07, left: getImgWidth()*0.43, top: getImgHeight()*0.075, zIndex: -1}}/>
            {/* DINING (DJ) */}
            <img src={`/contents/5.jpg`} style={{position: 'absolute', width: getImgWidth()*0.07, left: getImgWidth()*0.512, top: getImgHeight()*0.48, zIndex: -1}}/>
            {/* CAFE */}
            <img src={`/contents/4.jpg`} style={{position: 'absolute', width: getImgWidth()*0.07, left: getImgWidth()*0.122, top: getImgHeight()*0.5, zIndex: -1}}/>
            {/* CORRIDOR */}
            <img src={`/corridor/0.jpg`} style={{position: 'absolute', width: getImgWidth()*0.055, left: getImgWidth()*0.38, top: getImgHeight()*0.24, zIndex: -1, opacity: 0.75}}/>
            {/* ENTRANCE */}
            <img src={`/contents/6.jpg`} style={{position: 'absolute', width: getImgWidth()*0.055, left: getImgWidth()*0.01, top: getImgHeight()*0.247, zIndex: -1}}/>
          </>
        ) : <></>}
        {currentFloor===2 ? (
          <>
            {/* 206 x: 69.25~81.75 y: 58~70.25 */}
            <img src={`/art_rooms/206_FumimaroAyano.jpg`} style={{position: 'absolute', width: getImgWidth()*0.115, left: getImgWidth()*0.682, top: getImgHeight()*0.568, zIndex: -1}}/>
            {/* 212 x: 45.75~59.5 y: 10.5~21 */}
            <img src={`/art_rooms/212_MisaTsuetani.jpg`} style={{position: 'absolute', width: getImgWidth()*0.132, left: getImgWidth()*0.475, top: getImgHeight()*0.094, zIndex: -1}}/>
          </>
        ) : <></>}
        {currentFloor===3 ? (
          <>
            {/* 301 x: 68.98~73.98 y: 77.98~92.48 */}
            <img src={`/art_rooms/301_SanekatsuToda.jpg`} style={{position: 'absolute', width: getImgWidth()*0.0725, left: getImgWidth()*0.679, top: getImgHeight()*0.81, zIndex: -1}}/>
            {/* 312 x: 45.5~59.5 y: 8~18.25 */}
            <img src={`/art_rooms/312_MaoYoshino.jpg`} style={{position: 'absolute', width: getImgWidth()*0.13, left: getImgWidth()*0.475, top: getImgHeight()*0.07, zIndex: -1}}/>
          </>
        ) : <></>}
        {currentFloor===4 ? (
          <>
            <img src={`/roof_top/AiKobayashi.jpg`} style={{position: 'absolute', height: getImgHeight()*0.067, left: getImgWidth()*0.3, top: getImgHeight()*0.265, zIndex: -1, opacity: 0.75}}/>
            <img src={`/roof_top/ensoleille.jpg`} style={{position: 'absolute', height: getImgHeight()*0.067, left: getImgWidth()*0.375, top: getImgHeight()*0.265, zIndex: -1, opacity: 0.75}}/>
          </>
        ) : <></>}
        <SelectFloorModal
          showSelectFloorModal={showSelectFloorModal}
          setShowSelectFloorModal={setShowSelectFloorModal}
          setMoveBeforeEV={setMoveBeforeEV}
          setGracePeriod={setGracePeriod}
          setCurrentFloor={setCurrentFloor}
        />
        <ToiletModal
          showToiletModal={showToiletModal}
          setShowToiletModal={setShowToiletModal}
          showToiletName={showToiletName}
          setShowToiletName={setShowToiletName}
          moveBeforeToilet={moveBeforeToilet}
          setMoveBeforeToilet={setMoveBeforeToilet}
          waterSoundOn={waterSoundOn}
          setWaterSoundOn={setWaterSoundOn}
          setGracePeriod={setGracePeriod}
        />
        <RoomIntroModal
          showRoomIntroModal={showRoomIntroModal}
          setShowRoomIntroModal={setShowRoomIntroModal}
          showRoomName={showRoomName}
          setMoveBeforeRoom={setMoveBeforeRoom}
          setGracePeriod={setGracePeriod}
          waterSoundOn={waterSoundOn}
          setWaterSoundOn={setWaterSoundOn}
        />
        <Grid container justifyContent='center' alignItems='center' style={{
          position: 'absolute',
          cursor: 'pointer',
          width: getImgHeight() * 0.2,
          height: getImgHeight() * 0.2,
          right: getImgHeight() * (0.27-0.2/2),
          top: getImgHeight() * (0.27-0.2/2),
          border: '1px solid',
          borderRadius: '50%',
          fontSize: '12pt',
          borderColor: booting || !sharedArrayBufferEnable ? '#ccc' : 'black',
          color: booting || !sharedArrayBufferEnable ? '#ccc' : 'black',
        }} onClick={()=>{if(!booting && sharedArrayBufferEnable)boot()}}>
          {booting ? `` : `PLAY!`}
        </Grid>
        {booting ? (
          <>
            <div style={{
              position: 'absolute',
              cursor: 'pointer',
              width: 0,
              height: 0,
              right: getImgHeight() * (0.27-0.1),
              top: getImgHeight() * (0.27-(0.23)),
              borderLeft: `${getImgHeight() * 0.1}px solid transparent`,
              borderRight: `${getImgHeight() * 0.1}px solid transparent`,
              borderBottom: `${getImgHeight() * 0.1}px solid #ccc`,
            }}
            onTouchStart={()=>{if(isTouchDevice){setControllVY({x: 0, y: -50});setMoveBeforeEV(false);setMoveBeforeRoom(false);setMoveBeforeToilet(false);}}}
            onTouchEnd={()=>{if(isTouchDevice)setControllVY({x: 0, y: 0})}}
            onMouseDown={()=>{if(!isTouchDevice){setControllVY({x: 0, y: -50});setMoveBeforeEV(false);setMoveBeforeRoom(false);setMoveBeforeToilet(false);}}}
            onMouseUp={()=>{if(!isTouchDevice)setControllVY({x: 0, y: 0})}}
            />
            <div style={{
              position: 'absolute',
              cursor: 'pointer',
              width: 0,
              height: 0,
              right: getImgHeight() * (0.27-0.23),
              top: getImgHeight() * (0.27-(0.1)),
              borderLeft: `${getImgHeight() * 0.1}px solid #ccc`,
              borderTop: `${getImgHeight() * 0.1}px solid transparent`,
              borderBottom: `${getImgHeight() * 0.1}px solid transparent`,
            }}
            onTouchStart={()=>{if(isTouchDevice){setControllVY({x: 50, y: 0});setMoveBeforeEV(false);setMoveBeforeRoom(false);setMoveBeforeToilet(false);}}}
            onTouchEnd={()=>{if(isTouchDevice)setControllVY({x: 0, y: 0})}}
            onMouseDown={()=>{if(!isTouchDevice){setControllVY({x: 50, y: 0});setMoveBeforeEV(false);setMoveBeforeRoom(false);setMoveBeforeToilet(false);}}}
            onMouseUp={()=>{if(!isTouchDevice)setControllVY({x: 0, y: 0})}}
            />
            <div style={{
              position: 'absolute',
              cursor: 'pointer',
              width: 0,
              height: 0,
              right: getImgHeight() * (0.27-0.1),
              top: getImgHeight() * (0.27+(0.13)),
              borderLeft: `${getImgHeight() * 0.1}px solid transparent`,
              borderRight: `${getImgHeight() * 0.1}px solid transparent`,
              borderTop: `${getImgHeight() * 0.1}px solid #ccc`,
            }}
            onTouchStart={()=>{if(isTouchDevice){setControllVY({x: 0, y: 50});setMoveBeforeEV(false);setMoveBeforeRoom(false);setMoveBeforeToilet(false);}}}
            onTouchEnd={()=>{if(isTouchDevice)setControllVY({x: 0, y: 0})}}
            onMouseDown={()=>{if(!isTouchDevice){setControllVY({x: 0, y: 50});setMoveBeforeEV(false);setMoveBeforeRoom(false);setMoveBeforeToilet(false);}}}
            onMouseUp={()=>{if(!isTouchDevice)setControllVY({x: 0, y: 0})}}
            />
            <div style={{
              position: 'absolute',
              cursor: 'pointer',
              width: 0,
              height: 0,
              right: getImgHeight() * (0.27+0.13),
              top: getImgHeight() * (0.27-(0.1)),
              borderRight: `${getImgHeight() * 0.1}px solid #ccc`,
              borderTop: `${getImgHeight() * 0.1}px solid transparent`,
              borderBottom: `${getImgHeight() * 0.1}px solid transparent`,
            }}
            onTouchStart={()=>{if(isTouchDevice){setControllVY({x: -50, y: 0});setMoveBeforeEV(false);setMoveBeforeRoom(false);setMoveBeforeToilet(false);}}}
            onTouchEnd={()=>{if(isTouchDevice)setControllVY({x: 0, y: 0})}}
            onMouseDown={()=>{if(!isTouchDevice){setControllVY({x: -50, y: 0});setMoveBeforeEV(false);setMoveBeforeRoom(false);setMoveBeforeToilet(false);}}}
            onMouseUp={()=>{if(!isTouchDevice)setControllVY({x: 0, y: 0})}}
            />
            <Draggable
              position={{x: getControllButtonDefaultX(), y: getControllButtonDefaultY()}}
              nodeRef={nodeRef}
              onStart={()=>{setIsDrag(true);setMoveBeforeEV(false);setMoveBeforeRoom(false);setMoveBeforeToilet(false);}}
              onStop={()=>{setIsDrag(false)}}
              onDrag={handleDrag}
              scale={1}
              handle='b'>
              <div ref={nodeRef} style={{ position: 'absolute', cursor: 'pointer' }}>
                <b><div style={{
                  width: getImgHeight() * 0.18,
                  height: getImgHeight() * 0.18,
                  borderRadius: '50%',
                  backgroundColor: '#ccc',
                }}/></b>
              </div>
            </Draggable>
          </>
        ) : <></>}
      </div>

      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '20px'}}>
        {sharedArrayBufferEnable ? (
          <div style={{paddingTop: '5px', paddingBottom: '15px'}} >
            <div>
              <span style={{padding: '3px', fontSize: '11pt', color: '#1c1e33'}}>
                {`iPhoneの方はマナーモードを解除すると音が出ます`}
              </span>
            </div>
            <div>
              <span style={{padding: '3px', fontSize: '11pt', color: '#1c1e33'}}>
                {`For iPhone users, the sound will come out when the silent mode is deactivated.`}
              </span>
            </div>
          </div>
        ) : <></>}
      </div>
    </div>
  )
}

export default SoundMap