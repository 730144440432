import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Link as Scroll } from 'react-scroll';
import CloseModalButton from './CloseModalButton';
import BookButton from './BookButton';
import { MdOutlineWaterDrop } from 'react-icons/md';
import Button from '@mui/material/Button';
import { writerSentense } from './writer/WriterSentense';
import './scrolldown.css';

const modalStyle: Modal.Styles = {
  overlay: {
    position: "fixed",
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundColor: 'rgba(0,0,0,.4)',
  },
  content: {
    position: "fixed",
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    margin: 'auto',
    padding: '0px',
    width: '500px',
    maxWidth: '100vw',
    height: '425px',
    maxHeight: '85vw',
    color: '#000000',
    backgroundColor: '#fff',
    boxShadow: '0 10px 25px 0 rgba(30, 30, 30, .4)',
    borderRadius: '10px',
  }
};

type Props = {
  showRoomIntroModal: boolean;
  showRoomName: string;
  setShowRoomIntroModal: React.Dispatch<React.SetStateAction<boolean>>;
  setMoveBeforeRoom: React.Dispatch<React.SetStateAction<boolean>>;
  setGracePeriod: React.Dispatch<React.SetStateAction<boolean>>;
  waterSoundOn: boolean;
  setWaterSoundOn: React.Dispatch<React.SetStateAction<boolean>>;
}

type Room = {
  [roomName: string]: {
    artistName: string;
    imgUrls: string[];
  }
}

const roomInfo: Room = {
  'STUDIO': {
    artistName: 'Julie Hayashi',
    imgUrls: ['/studio/0.jpg', '/studio/1.jpg'],
  },
  'LOUNGE': {
    artistName: 'Teruyuki Sano',
    imgUrls: ['/lounge/0.jpg'],
  },
  'DJ': {
    artistName: 'POPPIN BEAR',
    imgUrls: ['/contents/5.jpg'],
  },
  'CAFE': {
    artistName: '期間限定メニュー',
    imgUrls: ['/contents/4.jpg'],
  },
  '108': {
    artistName: 'Hagoromo Okamoto',
    imgUrls: ['/art_rooms/108_HagoromoOkamoto90.jpg'],
  },
  '206': {
    artistName: 'Fumimaro Ayano',
    imgUrls: ['/art_rooms/206_FumimaroAyano.jpg'],
  },
  '212': {
    artistName: 'Misa Tsuetani',
    imgUrls: ['/art_rooms/212_MisaTsuetani.jpg'],
  },
  '301': {
    artistName: 'Sanekatsu Toda',
    imgUrls: ['/art_rooms/301_SanekatsuToda270.jpg'],
  },
  '312': {
    artistName: 'Mao Yoshino',
    imgUrls: ['/art_rooms/312_MaoYoshino.jpg'],
  },
  'ROOFTOP': {
    artistName: 'Ai Kobayashi',
    imgUrls: ['/roof_top/AiKobayashi.jpg'],
  },
  'ROOFTOP ': {
    artistName: 'ensoleille',
    imgUrls: ['/roof_top/ensoleille.jpg'],
  },
  'ENTRANCE': {
    artistName: 'Yoshihito Hasutani',
    imgUrls: ['/contents/6.jpg'],
  },
  'CORRIDOR': {
    artistName: '作品展示・販売',
    imgUrls: ['/corridor/0.jpg'],
  },
};

const RoomIntroModal: React.FC<Props> = ({ showRoomIntroModal, setShowRoomIntroModal, showRoomName, setMoveBeforeRoom, setGracePeriod, waterSoundOn, setWaterSoundOn }) => {
  const scrollDuration = 2000;
  const handleClose = () => {
    setShowRoomIntroModal(false);
    setMoveBeforeRoom(true);
    setGracePeriod(true);
    // setWaterSoundOn(false);
  }
  return (
    <Modal
      isOpen={showRoomIntroModal}
      onRequestClose={()=>{handleClose()}}
      style={modalStyle}
      ariaHideApp={false}
    >
      <div style={{paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px', paddingBottom: '15px', textAlign: 'center', height: '20px'}}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Scroll to={`${showRoomName.replace(/\s+/g,'')}`} smooth={true} duration={scrollDuration} delay={100} onClick={()=>{handleClose()}}>
            <span style={{fontSize: '13pt', cursor: 'pointer', textDecoration: 'underline'}}>{`${showRoomName}`}</span>
          </Scroll>
          <Scroll to={`${showRoomName.replace(/\s+/g,'')}`} smooth={true} duration={scrollDuration} delay={100} onClick={()=>{handleClose()}}>
            <span style={{fontSize: '12pt', cursor: 'pointer'}}>{`${roomInfo[showRoomName]?.artistName}`}</span>
          </Scroll>
          {/^\d*$/.test(showRoomName) ? (
            <BookButton/>
          ) : (
            <Button variant="contained" onClick={()=>setWaterSoundOn(v=>!v)} startIcon={<MdOutlineWaterDrop />} sx={{height: '36.5px', backgroundColor: waterSoundOn ? '#fff' : '#67a8dd', color: waterSoundOn ? '#67a8dd' : '#fff', "&:hover": {backgroundColor: waterSoundOn ? '#fff' : '#67a8dd'}}}>
              {waterSoundOn ? 'OFF' : 'ON'}
            </Button>
          )}
        </Grid>
      </div>
      <div style={{padding: '5px'}}/>
      <Divider/>
      <Grid container  width='100%' height='calc(100% - 60px)' justifyContent='center' alignItems='center' style={{padding: '10px', overflowY: /^\d*$/.test(showRoomName) ? 'scroll' : 'hidden'}}>
        {roomInfo[showRoomName]?.imgUrls.map((url, ind)=>{
          return <img key={ind} src={url} width={`${100/roomInfo[showRoomName]?.imgUrls.length}%`} style={{maxHeight: '100%'}}/>
        })}
        {/^\d*$/.test(showRoomName) ? (
          <div className="scrolldown1"><span>Scroll</span></div>
        ) : (
          <></>
        )}
        <div style={{paddingTop: '10px', paddingBottom: '5px', paddingLeft: '5px', paddingRight: '5px', whiteSpace: 'pre-wrap', backgroundColor: '#fff', zIndex: 1, fontSize: '10pt', lineHeight: '25px'}}>
          {/^\d*$/.test(showRoomName) ? (
            writerSentense[showRoomName]
          ) : (
            <></>
          )}
        </div>
      </Grid>
      <CloseModalButton handleClose={handleClose}/>
    </Modal>
  )
}

export default RoomIntroModal