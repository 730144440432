import React from 'react'

const BrowserWarning = () => {
  return (
    <div style={{fontSize: '9pt', whiteSpace: 'pre-wrap', width: '100%', padding: '10px'}}>
      {`最新バージョンのGoogle ChromeかSafari、`}
      <a
        href="https://caniuse.com/sharedarraybuffer"
        target="_blank"
        rel="noopener noreferrer"
      >
        {`その他対応ブラウザ`}
      </a>
      {`で開いてください。(ラインやインスタグラムのブラウザでは動きません)\n\n`}
      {`Sorry...This browser is not supported. Please open this site with `}
      <a
        href="https://caniuse.com/sharedarraybuffer"
        target="_blank"
        rel="noopener noreferrer"
      >
        {`a supported browser`}
      </a>
      {` such as Google Chrome or Safari.`}
    </div>
  )
}

export default BrowserWarning