import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import BubbleSquare from './soundBlock/BubbleSquare';
import RiverNoiseSquare from './soundBlock/RiverNoiseSquare';
import { map } from '../utils/mathFunc';
import { run } from '../utils/runScript';
import Divider from '@mui/material/Divider';
import { MAXPAGEWIDTH } from '../consts/const';

type ScProps = {
  sharedArrayBufferEnable: boolean;
  setSharedArrayBufferEnable: React.Dispatch<React.SetStateAction<boolean>>;
  booting: boolean;
  setBooting: React.Dispatch<React.SetStateAction<boolean>>;
}

const SoundBlocks: React.FC<ScProps> = ({ sharedArrayBufferEnable, setSharedArrayBufferEnable, booting, setBooting }) => {
  const [playButtonEnabled, setPlayButtonEnabled] = useState(false);
  const getImgWidth = () => document.getElementById('map')?.clientWidth || MAXPAGEWIDTH;
  const getImgHeight = () => getImgWidth() * (1500/1960);
  const boot = () => {
    run(`boot();d_rain();d_zure2();d_sinewave();d_pinknoise();`);
    setBooting(true);
  }
  useEffect(() => {
    if (booting) {
      setTimeout(()=>setPlayButtonEnabled(true), 500);
    }
  }, [booting])
  return (
    <div>
      <div style={{padding: '5px'}}/>
        <Divider/>
      <div style={{padding: '25px'}}/>
      <div style={{paddingLeft: '21px', paddingRight: '21px',paddingTop: '5px', paddingBottom: '15px', fontSize: '13pt', color: '#1c1e33'}}>
        {`電子環境音　electron environment sound`}
      </div>
      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33'}}>
        {`シンプルな電子音でも、音を積み上げることで旅の疲れを癒す環境音になります。`}
        {`（`}<a href='https://keita-miyano.web.app/sound_blocks' target="_blank" rel="noopener noreferrer">音の積み木</a>{`）`}
      </div>
      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33'}}>
        {`この画面からリアルタイムに生成される水の音をお楽しみください。`}
      </div>
      <div style={{padding: '3px'}}/>
      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33'}}>
        {`Even simple electronic sounds, when piled up, can become healing environment sounds.`}
      </div>
      <div style={{paddingLeft: '21px', paddingRight: '21px', paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33'}}>
        {`Enjoy the sound of water generated in real time from this page.`}
      </div>

      <div style={{padding: '5px'}}/>

      {!booting ? (
        <Grid container justifyContent='center' alignItems='center' style={{padding: '10px'}}>
          <Grid container justifyContent='center' alignItems='center' style={{
            cursor: 'pointer',
            width: getImgHeight() * 0.2,
            height: getImgHeight() * 0.2,
            border: '1px solid',
            borderRadius: '50%',
            fontSize: '12pt',
            borderColor: booting || !sharedArrayBufferEnable ? '#ccc' : 'black',
            color: booting || !sharedArrayBufferEnable ? '#ccc' : 'black',
          }} onClick={()=>{if(!booting && sharedArrayBufferEnable)boot()}}>
            {`PLAY!`}
          </Grid>
        </Grid>
      ) : <></>}

      <div style={{padding: '5px'}}/>

      <Grid container justifyContent='space-around' alignItems='center' style={{width: MAXPAGEWIDTH, maxWidth: '100%'}}>
        <RiverNoiseSquare
          booting={booting}
          setBooting={setBooting}
          playButtonEnabled={playButtonEnabled}
          defaultVolume={50}
        />
        <BubbleSquare
          booting={booting}
          setBooting={setBooting}
          playButtonEnabled={playButtonEnabled}
          startNodeId={3001}
          defaultSize={[5, 73]}
          defaultVolume={40}
          defaultBubbleCount={70}
        />
      </Grid>
      <div style={{padding: '30px'}}/>
    </div>
  )
}

export default SoundBlocks