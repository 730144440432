import React from 'react'
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Link as Scroll } from 'react-scroll';
import { MAXPAGEWIDTH } from '../consts/const';
import BookButton from './BookButton';
import { writerSentense } from './writer/WriterSentense';
import { write } from 'fs';

type WriterSentenseProps = {
  roomName: string;
}

const WriterSentenseDetails: React.FC<WriterSentenseProps> = ({ roomName }) => {
  return (
    <details style={{paddingTop: '5px', paddingBottom: '15px'}}>
      <summary>Writer Sentense</summary>
      <div style={{padding: '10px', fontSize: '10pt', whiteSpace: 'pre-wrap', lineHeight: '25px', backgroundColor: '#FCFCFC'}}>
        {writerSentense[roomName]}
      </div>
    </details>
  )
}

type Props = {
  setCurrentFloor: React.Dispatch<React.SetStateAction<number>>;
  setGracePeriod: React.Dispatch<React.SetStateAction<boolean>>;
  setShowRoomName: React.Dispatch<React.SetStateAction<string>>;
  setMoveBeforeRoom: React.Dispatch<React.SetStateAction<boolean>>;
}

const Contents: React.FC<Props> = ({ setCurrentFloor, setGracePeriod, setShowRoomName, setMoveBeforeRoom }) => {
  const scrollDuration = 2000;
  return (
    <div style={{paddingLeft: '21px', paddingRight: '21px'}}>

      <Divider/>

      <h2 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '14pt', color: '#1c1e33'}}>
        {`泊まれるアート展　ART ROOM`}
      </h2>

      <tr style={{paddingTop: '3px', paddingBottom: '3px', fontSize: '12pt', color: '#1c1e33', whiteSpace: 'pre-wrap'}}>
        <th style={{fontWeight: 'normal', width: '90px', textAlign: 'start'}}>
          4/15-4/16
        </th>
        <td>
          Show Room
        </td>
      </tr>
      <tr style={{paddingTop: '3px', paddingBottom: '3px', fontSize: '12pt', color: '#1c1e33', whiteSpace: 'pre-wrap'}}>
        <th style={{fontWeight: 'normal', width: '90px', textAlign: 'start'}}>
          4/17-5/15
        </th>
        <td>
          Averable Stay
        </td>
      </tr>

      <div style={{padding: '20px'}}/>

      <h3 id='206' style={{paddingTop: '10px', paddingBottom: '10px', fontSize: '14pt', color: '#1c1e33', margin : 0}}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(2);setGracePeriod(true);setShowRoomName('206');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
            206
          </Scroll>
          <BookButton/>
        </Grid>
      </h3>

      <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(2);setGracePeriod(true);setShowRoomName('206');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
        <div style={{paddingTop: '5px', paddingBottom: '15px', fontSize: '13pt', color: '#1c1e33'}}>「Hotel sculptures/ホテル・スカルプチャー」</div>
      </Scroll>

      <WriterSentenseDetails roomName={'206'}/>

      <div id='FumimaroAyano' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33', textDecoration: 'underline'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(2);setGracePeriod(true);setShowRoomName('206');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
          <span style={{cursor: 'pointer'}}>綾野 文麿　Fumimaro Ayano</span>
        </Scroll>
      </div>
      <img src='/artist_works/FumimaroAyano/concat.jpg' style={{width: MAXPAGEWIDTH, maxWidth: '100%', paddingTop: '10px', paddingBottom: '10px'}}/>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%'}}>
        {`1992年生まれ。言語と記憶を通して日常における「形骸化された行為」をテーマにインスタレーションやパフォーマンス、テキストなどの多様なメディアを用いて作品を制作している。`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`略歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`2020\t埼玉大学教養学部芸術論専攻　卒業\n2021\tKunstakademie Münster 留学（-2022）\n2023\t東京藝術大学美術研究科グローバルアートプラクティス専攻　修了`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`主な活動歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`2020\t「室内写真」（文華連邦・あをば荘・東京）\n\t\t「ex\\on」（dragged out studio・東京）\n2021\t「half & half」（ミュンスター、ドイツ）\n2022\t「月出アートキャンプ2022展」（月出工舎・千葉）\n2023\t「第71回東京藝術大学卒業・修了作品展」（東京藝術大学美術館）`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`SNS / Webサイト`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`Instagram:\t`}<a href='https://www.instagram.com/fumimaro_ayano/' target="_blank" rel="noopener noreferrer">@fumimaro_ayano</a>
      </div>

      <div style={{padding: '20px'}}/>

      <h3 id='301' style={{paddingTop: '10px', paddingBottom: '10px', fontSize: '14pt', color: '#1c1e33', margin : 0}}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(3);setGracePeriod(true);setShowRoomName('301');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
            301
          </Scroll>
          <BookButton/>
        </Grid>
      </h3>

      <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(3);setGracePeriod(true);setShowRoomName('301');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
        <div style={{paddingTop: '5px', paddingBottom: '10px', fontSize: '13pt', color: '#1c1e33'}}>「どこでもないどこか （ベッドのシワとカベのキズ）」</div>
      </Scroll>

      <WriterSentenseDetails roomName={'301'}/>

      <div id='SanekatsuToda' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33', textDecoration: 'underline'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(3);setGracePeriod(true);setShowRoomName('301');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
          <span style={{cursor: 'pointer'}}>戸田 尚克　Sanekatsu Toda</span>
        </Scroll>
      </div>
      <img src='/artist_works/SanekatsuToda/concat.jpg' style={{width: MAXPAGEWIDTH, maxWidth: '100%', paddingTop: '10px', paddingBottom: '10px'}}/>
      <div style={{paddingTop: '5px', paddingBottom: '15px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%'}}>
        {`「どこでもないどこか」を求めて表現活動を行なっています。`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`略歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`1995\t東京都町田市出身\n2018\tArt Collective “Residence Projekt” ( 現：iittee ) 発足 代表補佐\n2019\t東京芸術大学 美術学部 彫刻科 卒業\n2019\tミュンスター芸術アカデミー 留学（-2021）\n2022\t東京芸術大学 美術研究科 彫刻専攻 修了`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`主な活動歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`2021\t「Plakatwand Projekt der Klasse Löbbert」（ミュンスター、ドイツ）\n\t\t「WIEDERSEHEN an der FASSADE ‐ファサードにて‐」（ゲーテ・インスティトュート東京）\n2022\t「übersetze」（アイゼナハ、ドイツ）「16 th ART AWARD MARUNOUCHI 2022」（東京）   他`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`SNS / Webサイト`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`website:\t\t`}<a href='https://www.sanekatsutoda.com/' target="_blank" rel="noopener noreferrer">https://www.sanekatsutoda.com/</a>
        {`\n`}
        {`Instagram:\t`}<a href='https://www.instagram.com/sanekatsu.toda_artworks/' target="_blank" rel="noopener noreferrer">@sanekatsu.toda_artwork</a>
      </div>

      <div style={{padding: '20px'}}/>

      <h3 id='312' style={{paddingTop: '10px', paddingBottom: '10px', fontSize: '14pt', color: '#1c1e33', margin : 0}}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(3);setGracePeriod(true);setShowRoomName('312');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
            312
          </Scroll>
          <BookButton/>
        </Grid>
      </h3>

      <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(3);setGracePeriod(true);setShowRoomName('312');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
        <div style={{paddingTop: '5px', paddingBottom: '15px', fontSize: '13pt', color: '#1c1e33'}}>「筆跡の湿度」</div>
      </Scroll>

      <WriterSentenseDetails roomName={'312'}/>

      <div id='MaoYoshino' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33', textDecoration: 'underline'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(3);setGracePeriod(true);setShowRoomName('312');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
          <span style={{cursor: 'pointer'}}>吉野 マオ　Mao Yoshino</span>
        </Scroll>
      </div>
      <img src='/artist_works/MaoYoshino/concat.jpg' style={{width: MAXPAGEWIDTH, maxWidth: '100%', paddingTop: '10px', paddingBottom: '10px'}}/>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%'}}>
        {`大学卒業後、クリエイティブユニット”melme”を立ち上げる。 山手線を美術館にするプロジェクト”YAMANOTE LINE MUSEUM”のディレクションを始め、 アートイベントのキュレーション、商業施設のディレクションなどを手掛ける。 自身のアーティスト活動では、2022年10月に発表されたコムデギャルソンのコレクションに作品が起用される等、活動の幅も広げている。`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`略歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`1996\t愛知県出身\n2021\t東京芸術大学工芸科 陶芸研究室 卒業`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`主な活動歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`2021\t「東京藝術大学 卒業制作展」（東京都美術館）個展「La réponse est le malheur de la question.」（トタン・東京）\n2022\t「COMME des GARÇONS GIRL 2023 SSコレクション」`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`SNS / Webサイト`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`website:\t\t`}<a href='http://maoyoshino.com/' target="_blank" rel="noopener noreferrer">http://maoyoshino.com/</a>
        {`\n`}
        {`Twitter:\t\t`}<a href='https://twitter.com/l_maoyoshino_l/' target="_blank" rel="noopener noreferrer">@l_maoyoshino_l</a>
        {`\n`}
        {`Instagram:\t`}<a href='https://www.instagram.com/l_maoyoshino_l/' target="_blank" rel="noopener noreferrer">@l_maoyoshino_l</a>
      </div>

      <div style={{padding: '20px'}}/>

      <h3 id='212' style={{paddingTop: '10px', paddingBottom: '10px', fontSize: '14pt', color: '#1c1e33', margin : 0}}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(2);setGracePeriod(true);setShowRoomName('212');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
            212
          </Scroll>
          <BookButton/>
        </Grid>
      </h3>

      <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(2);setGracePeriod(true);setShowRoomName('212');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
        <div style={{paddingTop: '5px', paddingBottom: '15px', fontSize: '13pt', color: '#1c1e33'}}>「living & room」</div>
      </Scroll>

      <WriterSentenseDetails roomName={'212'}/>

      <div id='MisaTsuetani' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33', textDecoration: 'underline'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(2);setGracePeriod(true);setShowRoomName('212');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
          <span style={{cursor: 'pointer'}}>つえたにみさ　Misa Tsuetani</span>
        </Scroll>
      </div>
      <img src='/artist_works/MisaTsuetani/concat.jpg' style={{width: MAXPAGEWIDTH, maxWidth: '100%', paddingTop: '10px', paddingBottom: '10px'}}/>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%'}}>
        {`油彩絵画を制作の主軸に、インスタレーション、陶芸、服飾、雑誌型ポートフォリオの創刊など、それぞれの媒体が持つ意味や可能性について考えながら制作を行なっています。雑誌型ポートフォリオ「JIGAZO」は2019年、2022年に続いて2023年4月に新刊no.3を発売予定です。`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`略歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`1995\t鹿児島県指宿市生まれ\n2019\t東京芸術大学絵画科油画専攻卒業\n2023\t東京藝術大学美術研究科修士課程絵画専攻油画研究領域修了`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`主な活動歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`［個展］\n2021\t「お花摘み」(café lamp)\n2022\t「絵に描いたパンケーキ」(THE blank GALLERY)\n\t\t「POP UP ROOM」(OGUMAG＋)\n\n［グループ展］\n2018\t「EPIC PAINTERS」(THE blank GALLERY)\n2021\t「VIEWS and FACES」(THE blank GALLERY)\n2022\t「post imagin」(ASTER金沢)`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`SNS / Webサイト`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`Twitter:\t\t`}<a href='https://twitter.com/tsuetani/' target="_blank" rel="noopener noreferrer">@tsuetani</a>
        {`\n`}
        {`Instagram:\t`}<a href='https://www.instagram.com/tsuetani/' target="_blank" rel="noopener noreferrer">@tsuetani</a>
      </div>

      <div style={{padding: '20px'}}/>

      <h3 id='108' style={{paddingTop: '10px', paddingBottom: '10px', fontSize: '14pt', color: '#1c1e33', margin : 0}}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(1);setGracePeriod(true);setShowRoomName('108');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
            108
          </Scroll>
          <BookButton/>
        </Grid>
      </h3>

      <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(1);setGracePeriod(true);setShowRoomName('108');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
        <div style={{paddingTop: '5px', paddingBottom: '15px', fontSize: '13pt', color: '#1c1e33'}}>「CUT THE CRAP」</div>
      </Scroll>

      <WriterSentenseDetails roomName={'108'}/>

      <div id='HagoromoOkamoto' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33', textDecoration: 'underline'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(1);setGracePeriod(true);setShowRoomName('108');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
          <span style={{cursor: 'pointer'}}>岡本 羽衣　Hagoromo Okamoto</span>
        </Scroll>
      </div>
      <img src='/artist_works/HagoromoOkamoto/concat.jpg' style={{width: MAXPAGEWIDTH, maxWidth: '100%', paddingTop: '10px', paddingBottom: '10px'}}/>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%'}}>
        {`1990年生まれ。絵画、パフォーマンスを中心に制作する。日常に潜む人間の感情や心理行動を観察し、収集することで、普段わたしたちが見落とし、蓋をするような出来事に付随する不快な質を描き出す。`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`略歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`東京藝術大学 大学院美術研究科絵画専攻油画博士後期課程取得`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`主な活動歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`2017\t個展「今でも、なお、私は、朝食を、食べている(邦訳)」(SomoS・ベルリン)\n2019\t「Endless Void」(Democracy and Human Rights Memorial Hall・ソウル)\n2020\t個展「Gazing Horizontally」(ART DRUG CENTER・宮城)\n2022\t個展 「That’s that / ただ、それだけのこと」(CLEAR GALLERY TOKYO・東京)　　他`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`SNS / Webサイト`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`website:\t\t`}<a href='https://hagoromookamoto.tumblr.com/' target="_blank" rel="noopener noreferrer">https://hagoromookamoto.tumblr.com/</a>
        {`\n`}
        {`Instagram:\t`}<a href='https://www.instagram.com/hagoromookamoto/' target="_blank" rel="noopener noreferrer">@hagoromookamoto</a>
      </div>

      <div style={{padding: '20px'}}/>

      <h3 id='produce' style={{paddingTop: '10px', paddingBottom: '10px', fontSize: '14pt', color: '#1c1e33', margin : 0}}>
        プロデュース
      </h3>

      <div id='ArtBySansaki' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33', textDecoration: 'underline'}}>
        <Scroll to="ArtBySansaki" smooth={true} duration={scrollDuration}>
          <span style={{cursor: 'pointer'}}>ART BY SANSAKI</span>
        </Scroll>
      </div>
      <img src='/artist_works/ArtBySansaki/1.jpg' style={{width: MAXPAGEWIDTH/2, maxWidth: '50%', paddingTop: '10px', paddingBottom: '10px'}}/>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`さんさき坂カフェとART BY  SANSAKI、\nこの２つを営んでいます。\n\n`}
        {`アートは人と人が結びつく方法の一つと考えています。\n私の"場所"であるカフェに来た芸術家に、\n次はあなたの"場所"である作品を見せて欲しい。\nそしてその作品を、他の誰かに見せたい。\n\n`}
        {`こうして人と人が結びつくこと、\nそれを仕事にしています。\n\n`}
        {`今回、芸術家にはホテルの部屋に一日籠って考えてもらいました。\nその時、その場所で生まれた作品空間です。`}
      </div>

      <div style={{padding: '15px'}}/>

      <Divider/>

      <div style={{padding: '10px'}}/>

      <h2 id='STUDIO' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '14pt', color: '#1c1e33'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(1);setGracePeriod(true);setShowRoomName('STUDIO');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
          {`STUDIO EXHIBITION`}
        </Scroll>
      </h2>

      <div style={{paddingTop: '3px', paddingBottom: '3px', fontSize: '12pt', color: '#1c1e33', whiteSpace: 'pre-wrap'}}>
        {`4/15-4/16\t11:00-20:00`}
      </div>

      <div style={{padding: '10px'}}/>

      <div id='JulieHayashi' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33', textDecoration: 'underline'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(1);setGracePeriod(true);setShowRoomName('STUDIO');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
          <div style={{cursor: 'pointer'}}>林 樹里　Julie Hayashi</div>
        </Scroll>
      </div>

      <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(1);setGracePeriod(true);setShowRoomName('STUDIO');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
        <div style={{paddingTop: '10px', paddingBottom: '10px', fontSize: '13pt', color: '#1c1e33'}}>「meet in the forest 」</div>
      </Scroll>

      <img src='/artist_works/JulieHayashi/concat.jpg' style={{width: MAXPAGEWIDTH, maxWidth: '100%', paddingTop: '10px', paddingBottom: '10px'}}/>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%'}}>
        {`「おのずから現れるもの」や「うつろうもの」をテーマに、伝統的な日本画の技法・材料を用いて植物・風景・抽象を描く。実際に山や森へ赴いて取材し、時にその中で昼夜を過ごしてドローイングを行い、その場で感じた風や音、空気を作品に込める。\n\n今回は、今年3月に宮崎県諸塚村から東京を訪れHOTEL GRAPHY NEZUに滞在した子どもたちと、さんさき坂カフェで共同制作した作品を中心に展示する。`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`略歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`1989\t大阪府生まれ\n2013\t東京藝術大学 美術学部 芸術学科 卒業\n2015\t東京藝術大学大学院 保存修復日本画 修士課程 修了\n2018\t東京藝術大学大学院 保存修復日本画 博士課程 取得\n2019\t東京藝術大学 COI拠点 特任助手（-2023.3）`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`主な活動歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`2022\t「林樹里 特集 -sonatine–  ニュースター達の美術展2022」（阪急うめだ）\n\t\t「林樹里 日本画展 -andante-」（そごう広島）\n\t\t「3331 ART FAIR 2022」（3331 Arts Chiyoda・東京）\n\t\t「transient」個展（Gallery Art Composition・東京）\n2023\t「2023 Spring MITSUKOSHI Art Selection　林樹里 特集」（日本橋三越） 　他`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`SNS / Webサイト`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`website:\t\t`}<a href='https://www.julie884.com/' target="_blank" rel="noopener noreferrer">https://www.julie884.com/</a>
        {`\n`}
        {`Instagram:\t`}<a href='https://www.instagram.com/julie_hayashi/' target="_blank" rel="noopener noreferrer">@julie_hayashi</a>
      </div>

      <div style={{padding: '20px'}}/>

      <Divider/>

      <div style={{padding: '10px'}}/>

      <h2 id='LOUNGE' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '14pt', color: '#1c1e33'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(1);setGracePeriod(true);setShowRoomName('LOUNGE');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
          {`STUDIO EXHIBITION and sales of works`}
        </Scroll>
      </h2>

      <div id='TeruyukiSano' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33', textDecoration: 'underline'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(1);setGracePeriod(true);setShowRoomName('LOUNGE');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
          <span style={{cursor: 'pointer'}}>ジュエリーアーティスト　サノテルユキ</span>
        </Scroll>
      </div>
      <img src='/contents/3.jpg' style={{width: MAXPAGEWIDTH/2, maxWidth: '50%', paddingTop: '10px', paddingBottom: '10px'}}/>

      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`Biography`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`Kyoto Japan , 1995\n`}
        {`2018 Kyoto University Art and Design\n`}
        {`space design graduate\n`}
        {`2019 Royal College Of Art (London)\n`}
        {`postgraduate Art and Design\n`}
        {`Exchange Student Jewellery and Metal\n`}
        {`2020 Kyoto University Art and Design`}
      </div>

      <div style={{padding: '20px'}}/>
      
      <Divider/>

      <div style={{padding: '10px'}}/>

      <h2 id='ROOFTOP' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '14pt', color: '#1c1e33'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(4);setGracePeriod(true);setShowRoomName('ROOFTOP');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
          {`ROOFTOP PERFORMANCE`}
        </Scroll>
      </h2>

      <div id='ensoleille' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33', textDecoration: 'underline'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(4);setGracePeriod(true);setShowRoomName('ROOFTOP');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
          <span style={{cursor: 'pointer'}}>ensoleille</span>
        </Scroll>
      </div>

      <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(4);setGracePeriod(true);setShowRoomName('ROOFTOP');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
        <div style={{paddingTop: '10px', paddingBottom: '10px', fontSize: '13pt', color: '#1c1e33'}}>「浴びる」</div>
      </Scroll>

      <img src='/artist_works/ensoleille/concat.jpg' style={{width: MAXPAGEWIDTH, maxWidth: '100%', paddingTop: '10px', paddingBottom: '10px'}}/>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`コンテンポラリーダンスグループ。現在、日本女子体育大学ダンス学科に在学中。メンバーは、渡邊愛蓮、杉村心々虹、中村美空、水谷茉椰の4人。コンテンポラリーダンサーにおける可能性を見つけるため、日々探求している。大学でダンスに関する知識から実践的なことまで専門的に学びながら、学外での活躍の場を広げている。\n\n"陽だまりに集う私。\n谷根千の空気を飲む。\n歌うように光を放出する。"\n\n屋上でのパフォーマンス、コンテンポラリーダンス。4人のダンサーは、創作したものと即興的なアクションを組み合わせながら、外の世界と一体化する。常に新鮮な雰囲気を味わってもらえるようなパフォーマンスを提供します。`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`SNS / Webサイト`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`Instagram:\t`}<a href='https://www.instagram.com/luv_lotus.07/' target="_blank" rel="noopener noreferrer">@luv_lotus.07</a>
        {`\n`}
        {`Instagram:\t`}<a href='https://www.instagram.com/cooo421/' target="_blank" rel="noopener noreferrer">@cooo421</a>
        {`\n`}
        {`Instagram:\t`}<a href='https://www.instagram.com/39kauluwehi/' target="_blank" rel="noopener noreferrer">@39kauluwehi</a>
        {`\n`}
        {`Instagram:\t`}<a href='https://www.instagram.com/my0___21/' target="_blank" rel="noopener noreferrer">@my0___21</a>
      </div>

      <div style={{padding: '20px'}}/>

      <div id='AiKobayashi' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33', textDecoration: 'underline'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(4);setGracePeriod(true);setShowRoomName('ROOFTOP');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
          <span style={{cursor: 'pointer'}}>小林 藍　Ai Kobayashi</span>
        </Scroll>
      </div>

      <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(4);setGracePeriod(true);setShowRoomName('ROOFTOP');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
        <div style={{paddingTop: '10px', paddingBottom: '10px', fontSize: '13pt', color: '#1c1e33'}}>「フェイス オブ グラフィー」</div>
      </Scroll>

      <img src='/artist_works/AiKobayashi/concat.jpg' style={{width: MAXPAGEWIDTH, maxWidth: '100%', paddingTop: '10px', paddingBottom: '10px'}}/>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%'}}>
        {`1995年生まれ。在学中よりステンシルアートの制作を始める。粘土をいじるのも好き。`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`略歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`1995\t群馬県桐生市出身\n2018\t女子美術大学 ヴィジュアルデザイン専攻 卒業\n2019\tディスプレイ制作施工会社へ入社（-2022）\n2022\t同年より女子美術大学 ヴィジュアルデザイン専攻 助手`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`主な活動歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`2018\t個展「AIKOBAYASHI STENCIL WORKs」\n2022\t女子美術大学 助手展`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`SNS / Webサイト`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`Instagram:\t`}<a href='https://www.instagram.com/ai_kob_/' target="_blank" rel="noopener noreferrer">@ai_kob_</a>
      </div>

      <div style={{padding: '20px'}}/>


      <Divider/>

      <div style={{padding: '10px'}}/>

      <h2 id='DJ' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '14pt', color: '#1c1e33'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(1);setGracePeriod(true);setShowRoomName('DJ');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
          DJ
        </Scroll>
      </h2>

      <div id='PoppinBear' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33', textDecoration: 'underline'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(1);setGracePeriod(true);setShowRoomName('DJ');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
          <span style={{cursor: 'pointer'}}>POPPIN BEAR</span>
        </Scroll>
      </div>

      <img src='/contents/5.jpg' style={{width: MAXPAGEWIDTH/2, maxWidth: '50%', paddingTop: '10px', paddingBottom: '10px'}}/>

      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`登場場所は、どこでもコンセントのある所なら。\n`}
        {`仙台のDJ`}
      </div>

      <div style={{padding: '20px'}}/>

      <Divider/>

      <div style={{padding: '10px'}}/>

      <h2 id='walk_in_gallery' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '15pt', color: '#1c1e33'}}>
        {`ウォークインギャラリー`}
      </h2>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`スタッフ発案で、足跡の箇所にQRコードを設置し、かざすと10年前の写真から現在の風景へと移り変わるものを用意しています。`}
        {`\n\n`}
        {`また、カフェのスペースか、廊下の一部を使って、過去のGRAPHYの写真の展示を制作予定です。`}
      </div>
      <div style={{padding: '10px'}}/>
      <img src='/contents/1.jpg' style={{width: MAXPAGEWIDTH/2, maxWidth: '50%', paddingTop: '10px', paddingBottom: '10px'}}/>
      <div/>
      <img src='/contents/2.jpg' style={{width: MAXPAGEWIDTH/2, maxWidth: '50%', paddingTop: '10px', paddingBottom: '10px'}}/>

      <div style={{padding: '20px'}}/>

      <Divider/>

      <div style={{padding: '10px'}}/>

      <h2 id='CAFE' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '14pt', color: '#1c1e33'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(1);setGracePeriod(true);setShowRoomName('CAFE');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
          {`10年間の人気カフェメニューも期間限定で復活`}
        </Scroll>
      </h2>

      <img src='/contents/4.jpg' style={{width: MAXPAGEWIDTH/2, maxWidth: '50%', paddingTop: '10px', paddingBottom: '10px'}}/>

      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`これまでのCAFE GRAPHYの人気メニューが期間限定で復活致します。\n`}
        {`・鯖サンド\n`}
        {`・だし巻きサンド\n`}
        {`・ローストビーフサンド\n`}
      </div>

      <div style={{padding: '20px'}}/>

      <Divider/>

      <h1 id='writer' style={{paddingTop: '25px', paddingBottom: '5px', fontSize: '15pt', color: '#1c1e33'}}>
        {`ライター`}
      </h1>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`今回のアート企画では、複数のライターが集いアーティストの作品を文章で表現。アーティストと作品制作の前後で対話を重ね、言葉にしていきます。`}
        {`\n\n`}
        {`近藤 結子　Kondo Yuko\n東京藝術大学 美術学部芸術学科日本美術史専攻 在学中`}
        {`\n\n`}
        {`作田 壮紀　Souki Sakuta\n東京藝術大学 美術学部芸術学科美学専攻 在学`}
        {`\n\n`}
        {`奥山 満咲子　Misako Okuyama\n東京藝術大学 美術学部芸術学科日本美術史専攻 在学`}
        {`\n\n`}
        {`兼川 涼　Suzu Kanekawa\n東京藝術大学 美術学部芸術学科西洋美術史専攻 在学中`}
      </div>

      <div style={{padding: '20px'}}/>

      <Divider/>

      <h1 id='video' style={{paddingTop: '25px', paddingBottom: '5px', fontSize: '15pt', color: '#1c1e33'}}>
        {`VIDEO`}
      </h1>
      <div id='TakumiTachibana' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33', textDecoration: 'underline'}}>
        <Scroll to="TakumiTachibana" smooth={true} duration={scrollDuration}>
          <span style={{cursor: 'pointer'}}>橘 卓見　Takumi Tachibana</span>
        </Scroll>
      </div>

      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`東京大学医学部5年。免疫学の基礎研究に従事する傍ら、写真・映画をはじめとする映像領域で批評・創作活動を精力的に行う。`}
        {`\n\n`}
        {`今回は、アーティストの制作過程を記録したドキュメンタリーを撮影する。`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`略歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`2000\t兵庫県川西市出身\n2019\t東京大学理科Ⅲ類入学\n2021\t東京大学医学部医学科進学\n2023\t東京大学大学院情報学環教育部入学\n\t\tENBUゼミナール映画監督コース入学`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`主な活動歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`2022\t中編「誰しもにあるたった一つの未来」発表\n2023\t「都市と映像と身体のために」(雑誌「吾輩」)`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`SNS / Webサイト`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`Instagram:\t`}<a href='https://www.instagram.com/tachibananar/' target="_blank" rel="noopener noreferrer">@tachibananar</a>
      </div>

      <div style={{padding: '20px'}}/>

      <Divider/>

      <h1 id='ENTRANCE' style={{paddingTop: '25px', paddingBottom: '5px', fontSize: '15pt', color: '#1c1e33'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(1);setGracePeriod(true);setShowRoomName('ENTRANCE');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
          {`INSTALLER`}
        </Scroll>
      </h1>
      <div id='YoshihitoHasutani' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33', textDecoration: 'underline'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} onClick={()=>{setCurrentFloor(1);setGracePeriod(true);setShowRoomName('ENTRANCE');setMoveBeforeRoom(true);}} style={{cursor: 'pointer'}}>
          <span style={{cursor: 'pointer'}}>蓮溪 芳仁　Yoshihito Hasutani</span>
        </Scroll>
      </div>

      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`ヒトと共に移動する小さな建築空間の設計と実践から、巨大な空間世界とちっぽけな人間を繋ぐ方法について研究。イラストから看板、家具、建築、イベント、パフォーマンスに至るまで分野の垣根を越えた活動を行う。`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`略歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`1997\t滋賀県出身\n2020\t東京芸術大学 建築科 卒業\n2023\t東京芸術大学大学院 樫村芙実研究室 卒業`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`主な活動歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`2018\t住宅課題賞入賞\n2020\t「東京藝術大学 卒業制作展」（東京都美術館）\n2023\t「東京藝術大学 修了制作展」（東京藝術大学陳列館）\n2023\t東京藝術大学サロンドプランタン賞\n\t\tJIA大学院修士設計展最優秀賞\n\t\tトウキョウ建築コレクション最優秀賞`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`SNS / Webサイト`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`Twitter:\t\t`}<a href='https://twitter.com/hasu_to_sanpo/' target="_blank" rel="noopener noreferrer">@hasu_to_sanpo</a>
        {`\n`}
        {`Instagram:\t`}<a href='https://www.instagram.com/hasu_to_sanpo/' target="_blank" rel="noopener noreferrer">@hasu_to_sanpo</a>
      </div>


      <div style={{padding: '20px'}}/>

      <Divider/>

      <div style={{padding: '10px'}}/>

      <h2 id='WEB' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '14pt', color: '#1c1e33'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} style={{cursor: 'pointer'}} onClick={()=>{setCurrentFloor(1);setGracePeriod(true);setShowRoomName('');setMoveBeforeRoom(true);}}>
          WEB EXHIBITION
        </Scroll>
      </h2>

      <Scroll to="map" smooth={true} duration={scrollDuration} style={{cursor: 'pointer'}} onClick={()=>{setCurrentFloor(1);setGracePeriod(true);setShowRoomName('');setMoveBeforeRoom(true);}}>
        <div style={{paddingTop: '5px', paddingBottom: '20px', fontSize: '13pt', color: '#1c1e33'}}>「Assemble Sound Objects.」</div>
      </Scroll>

      <div id='KeitaMiyano' style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '13pt', color: '#1c1e33', textDecoration: 'underline'}}>
        <Scroll to="map" smooth={true} duration={scrollDuration} style={{cursor: 'pointer'}} onClick={()=>{setCurrentFloor(1);setGracePeriod(true);setShowRoomName('');setMoveBeforeRoom(true);}}>
          <span style={{cursor: 'pointer'}}>宮野 恵太　Keita Miyano</span>
        </Scroll>
      </div>
      <img src='/artist_works/KeitaMiyano/concat.jpg' style={{width: MAXPAGEWIDTH, maxWidth: '100%', paddingTop: '10px', paddingBottom: '10px'}}/>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%'}}>
        {`環境音アーティスト。プログラミング言語を用いて電子音を生成し、記憶の中の環境音や生き物の声を表現している。今年2月にはFEI ART MUSEUM YOKOHAMAで初個展を開催し、立体音響による空間表現を行った。`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`略歴`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`1991\t神奈川県横浜市出身\n2017\t東京大学工学部卒業\n2017\tNTTドコモ（AIエンジニア）\n2019\t不動産スタートアップestieを創業、CTOとして技術統括・研究開発などを行う\n2021\t独立後、async studio（アシンクスタジオ）社を設立\n2022\t環境音アーティストとしての活動、音の事業を本格的に開始\n2023\t初個展「電子音浴-中動態の環境音-」を開催`}
      </div>
      <h3 style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', margin: 0}}>
        {`SNS / Webサイト`}
      </h3>
      <div style={{paddingTop: '5px', paddingBottom: '5px', fontSize: '11pt', color: '#1c1e33', width: MAXPAGEWIDTH, maxWidth: '100%', whiteSpace: 'pre-wrap'}}>
        {`website:\t\t`}<a href='https://keita-miyano.async-studio.com/' target="_blank" rel="noopener noreferrer">https://keita-miyano.async-studio.com/</a>
        {`\n`}
        {`Instagram:\t`}<a href='https://www.instagram.com/keitamiyano_/' target="_blank" rel="noopener noreferrer">@keitamiyano_</a>
      </div>

      <div style={{padding: '20px'}}/>

    </div>
  )
}

export default Contents