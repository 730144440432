type WriterSentense = {
  [roomName: string]: string
}

export const writerSentense: WriterSentense = {
  '108': `WRITER 近藤 結子 | Kondo Yuko

　ひがな一日楽しく観光した夜、ホテルの部屋で一日を振り返ることは誰しも経験があることだろう。午前中ここにいった、混んでいたけど楽しかった、昼食がおいしかった、午後のあの景色は綺麗だった･･･その全てはホテルの部屋にないものだが、自分の記憶から（写真などの手助けを借りて）思い起こし、そのときの感動を反芻する。旅における楽しい時間のひとつである。そしてその後、「消灯」する瞬間がやってくる。布団に入り、電気を消す。パッと暗くなった部屋に、急激にホテルの無機質な空間を感じる。
そんな経験は無いだろうか。

　岡本羽衣による作品《CUT THE CRUP》は、この瞬間を思い起こさせる様に思う。
そしてその理由は、蛍光灯の使用にある。まずは作品の概要から順に追っていこう。

　本作品は、机の正面にある小さい作品と、ベッドの頭の方向にある大きな作品で構成されるが、ここでは大きい作品について触れる。
　始めに目に入るのは大きな布だ。これを便宜上”絵”と呼ぶことにする。
近づくとそれが不定形のキャンバスであることに気づく。色についても、灰色を主として黄や茶、青など様々な色が混じり合っていることに気づくだろう。しかしそこには具体的なイメージどころか、「熱」や「感謝」などの概念的なイメージすら存在しない。ただ布の描く不定形の曲線と、色のゆらぎが有機的に在るだけだ。それでもこの”絵”に対峙するならば、鑑賞者の思考は自然と布のかたさや重さなど、物体の質感や質量をなぞっていくことになる。ざらざらしていそう、重そう、絵の具がかたそう･･･
　ここに岡本が創り出す抽象の「しかけ」がある。「ざらざらしていそう」などと思うとき鑑賞者は、これまでの人生で触ってきた物の見た目と感触をセットで思い起こし、目の前の作品にあてはめているわけだ。そしてその記憶は、それにまつわる、例えば快/不快などの感情も喚起する。岡本の抽象は、鑑賞者に根源的な感覚にふれ直させ、感情を揺り動かす装置なのである。

さて、話を戻し「蛍光灯」の果たす役割に移る。
”絵”部分に対してこちらは完全な既製品であり、有機性や抽象性とは対照的だ。また、部屋の主たる照明でもあるこの蛍光灯は、作品を見る前提であり、鑑賞者が寝る際に消す照明でもある。よって蛍光灯を消すと”絵”は見えなくなり、記憶の中の感触を探索する行為も「打ち切られる」。ホテルの部屋という現実空間で行う旅の反芻と”絵”により喚起される感情の動きを、蛍光灯の「消灯」が同時に止めることにより、両者が接続されるのである。
ーそこに残されるのは、寝るための空間のみである。

余談だが、実際には、部屋にいれば蛍光灯を消しても作品の存在は感じられる。ほのかに油絵の具の匂いがするからだ。記憶の探索は打ち切られたが、そこで喚起されたものの残響は匂いに託され、目を閉じても感じられる。
さぁ、どうか明日はいい日でありますように―――――――――――寝言は寝てから言え！`,
  '206': `WRITER 作田 壮紀 | Souki Sakuta

　キャリーバッグを床に置く。上着を脱ぐ。道中で購入した土産物を整理する。ベッドに横たわる。
　これらは、我々がホテルの一室に入室した際に取る行動の一部である。
　扉が一つしかない冷蔵庫。最低限の作業台としての機能しか持たない机。同一の空間に押し込められた、洗面台と浴槽とトイレ。部屋の大部分を占める、威圧的なほど巨大なベッド。
　これらは、ホテルの客室を構成する要素の一部である。
　奇妙なものだ。こうして分析してみると、ホテルの客室という空間は我々の日常的生活環境からあまりにかけ離れている。そこに存在するのは、「居住に必要な最低限の要素を配置した、住空間らしき場所」にすぎない。にもかかわらず、我々は入室した瞬間から、あたかもその部屋が一時的に自宅や別荘となったかのように好き勝手に振舞い、寛ぐ。すなわち、我々は客室をまごうことなき住空間として認識――言い換えれば、無意識のうちに「信仰」している。
　綾野文麿が手がけた《Hotel sculptures/ ホテル・スカルプチャー》において、足を踏み入れた者がある意味強制的に自覚させられるのは、客室という場の持つ本来的な異質さであった。
　机は縦向きにされて部屋の隅に佇み、その机に備わっていたはずの引き出しは床に置き去りにされ、浴室の壁には空き箱が並んで貼り付けられ、鏡の前には祭壇とも呼ぶべき構造が設えられる。もともと室内の備品であった家具が使用には適さない形で再構成されることで、その部屋は住空間であることをやめ、住空間を象った彫刻（sculpture）となる。
　綾野は日常における「形骸化された行為」を主題として制作を行っている。例えば毎朝部屋の隅の神棚に向かったり、より身近な例で言えば食事の前後に手を合わせたり――そういった行為が本質的に持つ儀式性は、日常に組み込まれることによって、消失する。
　本作品に即して言えば、非日常として扱われているのは、ある特定の行為というより空間それ自体である。さらにその空間の中に、形骸化された機能を捨てた個々の要素が置かれている。鑑賞者＝宿泊客はこの部屋で、まずホテルの客室の特殊性と、今までそこに一切の違和感を抱かずに客室を住空間として信仰してきた自分を、反省的に認識してたじろぐことになる。続いて、綾野による再構成の結果から、痕跡を探り、この状況に至るまでの経緯を想像することとなる。
　短時間の鑑賞ではなく長時間にわたる滞在だからこそ、鑑賞者の中で次第に部屋全体に対する解像度が上がっていく。こうした体験そのものが鑑賞として異質であり、ますます非日常へと誘われていく。`,
  '212': `WRITER 作田 壮紀 | Souki Sakuta

　明るくて、写実的で、一目で引き込まれる画面に描かれるのは、どこか不穏な雰囲気を醸し出すモチーフ。そんな初見での印象のちぐはぐさに誘い込まれて、思わず足を止めて眺めていると、澄み渡る青空をまっすぐに横切るミサイルの存在や、画面に込められた痛烈な皮肉に不意に気がついて、ぎくりとする。と同時に作品が一層輝きを増して見え始め、いつしか虜になっている。たった一度でもその経験をしてしまった鑑賞者は、つえたにみさの生み出す世界に囚われ、二度と逃れることができなくなる。
《living & room》において、つえたには客室内にもともと置かれていた家具類に手を加えることはせず、壁面を絵画作品で埋め尽くす形で展示を行った。その結果、滞在・宿泊の空間としての合理性を保ちながら鑑賞のための空間としても成り立っている、「絵とともに暮らす部屋」が完成した。
　宿泊客は、生活の一部に組み込まれた鑑賞を体験することとなる。そうして見ていくと、展示された作品自体にも、つえたに自身の暮らしであったり現実を生きる中での気づきであったりを主題としているものが多いことがわかるだろう。
　青空の下、屋根も壁もない異様な環境で日常生活を営む家族。喫煙所に群がる人々。見覚えのある顔が隠された食事会の光景。すべて、実際に起こった何らかの出来事とそれに直面したつえたにの感情が絵画に落とし込まれている。かといって、それらは現実を批判する目的で描かれているわけでは決してなく、むしろ現実を知的に風刺し、軽快に笑い飛ばす。
　また、そうした作品とは別に、つえたにが気の向くままに手を動かした作品も存在する。例えば餃子を並べた絵画では、餃子のひとつが鳥の頭に見えたのをきっかけに、そのまま鳥として仕上げている。絵画に限らず衣服やアクセサリーにも、ある種の即興性が見られる。
　制作者として、つえたにの中には二種類の自分が存在しているという。
一方は、綿密な構想に基づいてアイロニカルな世界を描く自分。他方は、事前の構想を多少変更してでも――あるいは、あえて何も決めずに作り始め――自身が純粋にかわいいと思えるものや好きになれるものを目指していく自分。つえたにの中では、かなり計画的に二つの自分の使い分けがなされているという。
　しかし、いずれの場合でも、「気づき」が制作の中心にあるように思われる。
　日々を生きていく中で、ふと思い当たった違和感をきっかけに考え始める。描いている途中で、餃子のひとつが鳥に見えるのを発見する。つえたには常に、自身の感性に真摯に向き合っている。
　だからこそ、小さな「棘」が仕込まれていても、断じて攻撃的ではなく、むしろ親しみを持てる作品が生まれるのだ。`,
  '301': `WRITER 兼川 涼 | Suzu Kanekawa

　３０１号室に入るとすぐに、ベッド上方の空間へ斜めに差し込むように吊るされた、木枠に貼られた布が目に入るだろう。 ベッドに上がってみると、覆いかぶさってきそうな作品が間近に配置されていることで、本来リラックスできるはずの場所が一変、非日常的な雰囲気を纏う。布地に表現されているのは窓から見える空と建物の写真、そして本作品のタイトルにもなっている言葉“Somewhere, but nowhere”。空の部分にある「どこか(Somewhere)」の文字が、窓の外からの光を受けて際立っている。しかし、部屋の明かりをつけると「どこでもない(nowhere)」が浮かび上がった。不特定な「どこか」は空の向こうへ馳せる想いと繋がっている一方で、すぐに暗がりに埋もれて「どこでもなく」なってしまうようにも思える。
　ベッドサイドにはクリップで止められた紙片があるが、これは部屋の壁にあった傷の拓本だという。ものの表面に紙や絹を被せ、上から墨を打って凹凸を写しとるという手法だ。ベッド上の大きな物体に目を引かれがちだが、その向こうの壁紙には確かに傷がついている。部屋に刻まれた痕跡を探し照らし合わせるのは、その場所の歴史を調査しているようで面白い。
　ドア近くに目を移すと、空き地の看板を写した写真が貼ってある。なんのために立てられたかもわからない空白の看板に、戸田は自身の印刷した写真を二枚、貼り付けた。
　清掃で消されてしまうベッドのシワ、残り続ける壁の傷。空き地に立てられた看板と、そこには残っていない本来のメッセージ。戸田は、空間に刻まれる人の痕跡に着目する。しかし、その制作のプロセスそのものはむしろ新たに痕跡を「つくらない」「残さない」ものであるといえそうだ。というのも、今回の展示に当たってこの部屋に元々あった家具のレイアウトなどには全く手を加えていないのだという。２０６号室とは対照的に、作品はあくまで、従来の部屋にある空白にそっと差し込むように配置されている。
　戸田はインタビューにて、毎回新作を制作するというスタンスを明かしてくれた。また、いわゆるホワイトキューブではなく、日常の風景のなかに溶け込むような作品を製作しているのだとも語った。インスタレーションという手法は展示空間と密接に繋がっており、場所を移せば完全には成り立たなくなってしまう。その特徴は、戸田自身の「場所の文脈に合わせた表現」と呼応しているのだろう。
　私たちは日々、ある場所に身を置き、知らず知らずのうちに生活の痕跡を残していく。ここの作品は、そんな「どこでもないどこか」に積み重ねられた物語に気づかせてくれる。`,
  '312': `WRITER 奥山 満咲子 | Misako Okuyama　

部屋の奥の壁一面に広がる巨大なドローイング、《筆跡の湿度》。アクリル絵の具とクレヨンの鮮やかな赤色や鉛筆の黒色など、様々な画材の質感や色の筆跡の動きを辿るだけでも見ごたえのある作品こそ、今回吉野が担当の部屋で取り組んだ作品だ。
　吉野の作品を制作するにあたっての姿勢について、制作の際の「祈り」と「動機」という二つの点から説明することができるように思う。
　まず「祈り」とは、作家本人である吉野自身からそれ以外の他者への「それぞれ独立したうえで共存したい」というものだ。大学時代、陶芸を学んだ吉野は土という素材に強く関心を示し、地球という他者と自分自身が違う存在でありながらも違いをみとめて共生するというテーマで制作に取り組んだという。土や地球ということ自体に興味があるというよりも、自分と相手が異なる存在ということを意識したうえで相手を尊重するという考え方こそが、現在にもつながる制作における重要な軸であるようだ。自身の経験から、他者とまったく別の存在であるということを自覚しているほうが、より他者である相手に寛容に接することができると考える吉野は、自分の感情や情動を表現する作品を創り出す過程で、制作に使用する画材や鑑賞者となる他者がありのままでいられるように、という祈りを強く持っている。
　もう一方の「動機」は、吉野が制作を始めるきっかけとなった現象についてである。吉野自身は自分の制作の起源は幼少期に友人や家族に宛てた手紙であると説明している。最初こそ誰もが想像するような文字だけの手紙であったが、湧き上がる感情を伝えるため、幼い吉野は様々な表現方法に挑戦するようになった。手紙にそのまま絵を描きこんでみたり、色紙でちぎり絵を作ってみたり、時には開けば絵が飛び出してくるメッセージカードのような作品にまで取り組んだと言う。吉野にとって制作とは、溢れる感情の出力方法であり、画材も描かれるものも、感情にあわせて定まっていく副次的なものにすぎない。水を飲みすぎたら苦しくなって吐く、それくらい自然で生理現象と変わらないものだと吉野は述べた。
　こうした「祈り」と「動機」によって制作された《筆跡の湿度》は、そんな吉野が宿泊者に自由に解釈して欲しいという「祈り」と部屋の中で高めた気持ちが「動機」となって生まれた作品で、部屋の壁一面を覆い、強烈な存在感を放っている。宿泊部屋の一面の壁となった作品は、短期間ながらも鑑賞者と生活を共にするため、同じ人であっても鑑賞者の気分や見る時間帯、光の加減によって多様な表情を見せるだろう。シチュエーションによって変化する鑑賞体験を楽しんでほしいし、宿泊した方の感想を聞いてみたい、と吉野は話した。`
}